<script lang="ts">
import { defineComponent, inject, PropType, watch } from 'vue';
import InputField from '@/Components/InputField.vue';
import { InputType, FileType } from '@/Types/InputTypes';
import { MaskType } from '@/Types/MaskTypes';
import { ICalculadoraStep3 } from '@/Types/Calculadora';
import { Money } from '@/utils/formats'

export default defineComponent({
    name: "FormularioCalculadoraStep3",
    components: {
        InputField,
    },
    props: {
        calculadora: {type: Object as PropType<ICalculadoraStep3>, required: true},
        editable: {type: Boolean},

    },
    data() {
        return {
            InputType,
            MaskType,
            FileType,

            Money,
            errors: {} as Record<string, string>,
        }
    },
    methods: {
        calcularPatrimonioLiquido() {
            if (this.calculadora.ativo_circulante && this.calculadora.ativo_nao_circulante &&
                this.calculadora.passivo_circulante && this.calculadora.passivo_nao_circulante) {
                const ativoTotal = Number(this.calculadora.ativo_circulante) + Number(this.calculadora.ativo_nao_circulante);
                const passivoTotal = Number(this.calculadora.passivo_circulante) + Number(this.calculadora.passivo_nao_circulante);
                this.calculadora.patrimonio_liquido = ativoTotal - passivoTotal;
                if (this.calculadora.patrimonio_liquido == 0) {
                    this.errors.patrimonio_liquido = 'O valor não pode ser zero.';
                } else {
                    this.errors.patrimonio_liquido = '';
                }
            } else {
                this.calculadora.patrimonio_liquido = Number('');
            }
        },
    },
    mounted() {
        this.calcularPatrimonioLiquido();
    },
    watch: {
        calculadora: {
            handler: 'calcularPatrimonioLiquido',
            deep: true,
        }
    }
})
</script>

<template>
    <div class="step flex flex-col gap-[1rem]" id="step-2">
        <div>
            <p class="font-bold">Passo 3: Balanço Atualizado</p>
            <p class="text-[1.2rem]">Preencha os campos e
                <label for="arquivo_balanco_patrimonial" class="hover:underline cursor-pointer font-medium">clique aqui</label> 
                para importar um arquivo (opcional)
            </p>
            <div id="arquivo_balanco_patrimonial-error" class="dark-blue error-field inadimplente font-small p-4 mr-[1.3rem] py-[.5rem] hidden rounded-lg">
            </div>  
            <InputField name="arquivo_balanco_patrimonial" v-model:value="calculadora.arquivo_balanco_patrimonial" :type="InputType.FILE" :file-type="FileType.PDF" hidden/>
        </div>

        <div class="grid grid-cols md:grid-cols-2">
            <div class="mt-[2rem]">
                <h3 class="font-medium">Ativo</h3>
                <InputField
                    name="ativo_circulante"
                    :mask="MaskType.DINHEIRO"
                    :is-required="true"
                    v-model:value="calculadora.ativo_circulante"
                    placeholder=" "
                    labelStyles="font-size: 1.4rem"
                    label="Circulante"
                    font-size="15px"
                    :type="InputType.TEXT"
                    :col-span="1"/>

                <div class="pl-[4rem]">
                    <InputField
                        name="caixa_e_aplicacoes_financeiras"
                        :mask="MaskType.DINHEIRO"
                        :is-required="true"
                        v-model:value="calculadora.caixa_e_aplicacoes_financeiras"
                        placeholder=" "
                        labelStyles="font-size: 1.4rem"
                        label="Caixa e Aplicações Financeiras"
                        font-size="15px"
                        :type="InputType.TEXT"
                        :col-span="1"/>
                    <InputField
                        name="estoques"
                        :mask="MaskType.DINHEIRO"
                        :is-required="true"
                        v-model:value="calculadora.estoques"
                        placeholder=" "
                        labelStyles="font-size: 1.4rem"
                        label="Estoques/Imóveis a Comercializar"
                        font-size="15px"
                        :type="InputType.TEXT"
                        :col-span="1"/>
                </div>

                <InputField
                    name="ativo_nao_circulante"
                    :mask="MaskType.DINHEIRO"
                    :is-required="true"
                    v-model:value="calculadora.ativo_nao_circulante"
                    placeholder=" "
                    labelStyles="font-size: 1.4rem"
                    label="Não Circulante"
                    font-size="15px"
                    :type="InputType.TEXT"
                    :col-span="1"/>
            </div>

            <div class="mt-[2rem]">
                <h3 class="font-medium">Passivo</h3>
                <InputField
                    name="passivo_circulante"
                    :mask="MaskType.DINHEIRO"
                    :is-required="true"
                    v-model:value="calculadora.passivo_circulante"
                    placeholder=" "
                    labelStyles="font-size: 1.4rem"
                    label="Circulante"
                    font-size="15px"
                    :type="InputType.TEXT"
                    :col-span="1"/>
                <InputField
                    name="passivo_nao_circulante"
                    :mask="MaskType.DINHEIRO"
                    :is-required="true"
                    v-model:value="calculadora.passivo_nao_circulante"
                    placeholder=" "
                    labelStyles="font-size: 1.4rem"
                    label="Não Circulante"
                    font-size="15px"
                    :type="InputType.TEXT"
                    :col-span="1"/>
                <InputField
                    name="financiamentos"
                    :mask="MaskType.DINHEIRO"
                    :is-required="true"
                    v-model:value="calculadora.financiamentos"
                    placeholder=" "
                    labelStyles="font-size: 1.36rem"
                    label="Empréstimos e Financiamentos (Curto e Longo Prazo)"
                    font-size="15px"
                    :type="InputType.TEXT"
                    :col-span="1"/>
                        
            </div>
            <div class="flex mt-[1rem]">
                <p class="text-[1.4rem]">Ativo Total: </p>
                <p
                    v-if="calculadora.ativo_circulante && calculadora.ativo_nao_circulante"
                    class="ml-[1rem] text-[1.4rem] font-bold">
                    {{ Money(Number(calculadora.ativo_circulante) + Number(calculadora.ativo_nao_circulante)) }}
                </p>
                <p v-else class="ml-[1rem] text-[1.4rem] font-bold">{{Money(0)}}</p>
            </div>
            <div class="flex mt-[1rem]">
                <p class="text-[1.4rem]">Passivo Total: </p>
                <p
                    v-if="calculadora.passivo_circulante && calculadora.passivo_nao_circulante"
                    class="ml-[1rem] text-[1.4rem] font-bold">
                    {{ Money(Number(calculadora.passivo_circulante) + Number(calculadora.passivo_nao_circulante) + Number(calculadora.patrimonio_liquido)) }}
                </p>
                <p v-else class="ml-[1rem] text-[1.4rem] font-bold">{{Money(0)}}</p>
            </div>
            <div class="flex mt-[1rem] flex-col ">
                <div class="flex">
                    <p class="text-[1.4rem]">Patrimônio Líquido: </p>
                    <p class="ml-[1rem] text-[1.4rem] font-bold">{{ Money(calculadora.patrimonio_liquido) }}</p>
                    
                </div>
                
                <div id="patrimonio_liquido-error" class="error-field text-inadimplente font-small hidden">
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
