<script lang="ts">
import { PropType, defineComponent } from 'vue';
import { InputType } from '@/Types/InputTypes';
import  InputField from '@/Components/InputField.vue';
import { IInvestimentoAutenticacao } from '@/Types/Investimento';
import {goToUrl} from '@/services/Browser';

export default defineComponent({
    name: "FormularioInvestimentoAutenticacao",
    props : {
        autenticacaoInstance:{type: Object as PropType<IInvestimentoAutenticacao>, required: true},
        authData: {type: Object, required: true}
    },
    components: {
        InputField,
    },
    data() {
        return {
            InputType,
            
            goToUrl,
        }
    },
    methods: {
    },
    mounted() {
    }
})

</script>

<template>
    <div class="flex flex-wrap flex-col justify-between items-start py-[2rem] md:flex-row">
        <div class="flex flex-col h-full w-full gap-[2.5rem] md:gap-0 md:w-[47%] grey-label font-medium ">
            <div class="text-start md:h-[19.5rem]">
                <h3 class="dark-blue font-medium mb-4">Formalização</h3>
                <p>Para formalizar seu investimento, será necessário a assinatura eletrônica do seu título de investimento. <br>
                Para validar a sua assinatura, é necessário realizar uma verificação em 2 fatores utilizando seu celular ou e-mail.</p>
            </div>
            <div class="flex items-center gap-4 grey-label">
                <InputField
                :type="InputType.CHECKBOX"
                v-model:value="autenticacaoInstance.de_acordo"
                :isRequired="true"
                checkBoxColor="light-blue-bg"
                name=""
                :noLabel="true"/>
                <p class="pointer" @click="autenticacaoInstance.de_acordo = !autenticacaoInstance.de_acordo">Entendi e desejo prosseguir no processo de investimento</p>
            </div>
        </div>
        <div class="w-full my-[2rem] justify-center md:my-0 md:flex flex-[1_0_2%]">
            <div class="">
                <div class="w-full md:h-[24.5rem] min-[1107px]:h-[23rem] border-standard">
                </div>
            </div>
        </div>

        <div class="flex flex-col gap-[2.5rem] w-full md:w-[49%]">
            <div class="flex flex-col gap-2">
                <h2 class="font-medium">Receber por</h2>
                <InputField
                    radioClasses="flex-col mt-[1rem] min-[387px]:flex-row md:text-[1.4rem] min-[826px]:text-[1.6rem]"
                    inputContainerClass="px-0"
                    :type="InputType.RADIO"
                    v-model:value="autenticacaoInstance.sign_method"
                    selectId="teste"
                    :selects="[
                        {label: 'Whatsapp', radiusValue: 'whatsapp'},
                        {label: 'SMS', radiusValue: 'sms'},
                        {label: 'E-mail', radiusValue: 'email'},
                    ]"
                    checkBoxColor="lightI-blue-bg"
                    name=""
                    :noLabel="true"/>
            </div>
            <div class="flex flex-col gap-[1rem]">
               <h2 class="font-medium">Código de autenticação</h2>
               <div>
                   <p>Você receberá um código no
                        <span v-if="autenticacaoInstance.sign_method == 'email'">
                            e-mail:
                        </span>
                        <span v-else>
                            celular:
                        </span>
                    </p> 
                   <p class="font-bold underline">
                        <span v-if="autenticacaoInstance.sign_method == 'email'">
                            {{ authData.email }}
                        </span>
                        <span v-else>
                            {{ authData.telefone }}
                        </span>
                    </p>
               </div>
            </div>
            <div class="grey-label" v-if="autenticacaoInstance.sign_method != 'email'">
                Caso deseje alterar o número, <span class="font-bold underline pointer" @click="goToUrl('/api/investidor/meus-dados/redirect/')">clique aqui</span>.
            </div>
        </div>
    </div>

</template>