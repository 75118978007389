<script lang="ts">

import { Money, Percent, ToDate } from '@/utils/formats'
import infoIcon from '@/assets/info-icon.svg';
import GoogleMap from './GoogleMap.vue';
import { isMobile } from '@/services/Mobile';
import LineChart from '@/Components/LineChart.vue';
import { getOffset } from '@/services/Browser';
import { colors } from '@/styles/styles';

export default {
    name: "CardRatingProjeto",
    props: {
        calculadora: {type: Object, default: undefined},
    },
    components: {
        GoogleMap,
        LineChart,
    },
    data() {
        return {
            colors,
            Money,
            Percent,
            ToDate,
            getOffset,

            mobile: isMobile(),


            infoIcon,
            windowTop: 0,
            isFixed: false,
    };
  },
  methods: {
    fixedOnTop() {
      let element = this.$refs.ratingProjeto as HTMLElement;
      if (!element) return;
      
      const top = this.getOffset(element).top;
      this.windowTop = window.scrollY + 150;

      if (this.windowTop > top) {
        this.isFixed = true;
      } else {
        this.isFixed = false;
      }

      window.addEventListener('scroll', this.fixedOnTop);
    },
  },
  mounted() {
    this.fixedOnTop();
  },
    
}

</script>

<template>
    <div class="lg:sticky lg:h-[88px] lg:top-[14.3%] lg:pt-[1rem] lg:bg-white lg:z-10">
        <div class="flex flex-col lg:grid grid-cols-3 gap-[3rem] w-full lg:gap-[2rem] xl:gap-[5rem] 2xl:gap-[10rem] lg:items-center">
            <h2 class="lg:col-span-1 font-medium text-[3rem] lg:text-[3.6rem]">O Empreendimento</h2>
            <h2 class="lg:col-span-1 font-medium text-[3rem] lg:text-[3.6rem] lg:truncate lg:max-w-[25rem] xl:max-w-[30rem] 2xl:max-w-[40rem]" :title="calculadora?.nome_projeto">{{ calculadora?.nome_projeto }}</h2>
            <div class="lg:col-span-1 flex items-center gap-[2rem]">
                <p class="font-medium text-[1.8rem] grey-label">Rating </p>
                <p class="text-black text-[2.8rem] lg:text-[3.8rem]">{{ calculadora?.conceito_projeto }}</p>
            </div>
        </div>
        <div class="border-[.1rem] border-solid border-[#001a42] my-[2rem]"></div>
    </div>

    <div class="flex flex-col gap-[5rem] mt-20">
        <section id="mapa" class="flex flex-col gap-[3rem]">
            <div v-if="calculadora" class="flex flex-col gap-[.5rem] w-full">
                <GoogleMap :reload-map="false" :mapUrl="calculadora.url_mapa" :data="[{'latitude': calculadora?.latitude, 'longitude': calculadora?.longitude}]" tipo='location' :showTitle="false" classes="w-full h-[44rem] lg:h-[34rem] rounded-3xl"/>
            </div>
            <div v-else class="skeleton w-full h-[44rem] lg:h-[34rem] rounded-3xl"></div>
            <div class="flex flex-col  lg:max-h-[34rem]" :class="!calculadora ? 'lg:gap-[1rem]' : 'lg:justify-between lg:gap-[3rem]'">
    
                <div v-if="calculadora" class="text-[1.8rem]">
                    <p><a :href="calculadora.url_mapa" target="_blank" class="font-medium pointer">{{ calculadora?.endereco_completo }}</a></p>
                    <p><a v-if="calculadora?.walkability" :href="calculadora.url_mapa" target="_blank" class="pointer"><span class="font-bold">Walkability:</span> {{ Percent(calculadora?.walkability) }}</a></p>
                </div>
                <div v-else>
                    <div v-if="!mobile" class="flex flex-col gap-[1rem]">
                        <div class="skeleton w-[50%] h-[3rem] rounded-3xl">
                            
                        </div>
                        <div class="skeleton w-[30%] h-[3rem] rounded-3xl">
                        </div>
                    </div>
                    <div v-else class="flex flex-col gap-[2rem]">
                        <div class="flex flex-col gap-[.5rem]">
                            <div class="skeleton skeleton-text rounded-3xl"></div>
                            <div class="skeleton skeleton-text rounded-3xl"></div>
                            <div class="skeleton skeleton-text rounded-3xl"></div>
                            <div class="skeleton skeleton-text rounded-3xl"></div>
                            <div class="skeleton skeleton-text rounded-3xl"></div>
    
                        </div>
                        <div class="flex flex-col gap-[.5rem]">
                            <div class="skeleton skeleton-text h-[5rem] rounded-3xl"></div>
                            <div class="skeleton skeleton-text h-[5rem] rounded-3xl"></div>
                        </div>
                    </div>
                </div>
    
            </div>
        </section>
    
        <template id="cronogramas" v-if="calculadora">
            <section v-if="calculadora.vendas_projecoes || calculadora.obra_projecoes">
                <div v-if="calculadora" class="flex flex-col gap-[3rem]">
                    <div class="flex justify-between">
                        <h3 class="font-medium text-[2.5rem] dark-blue lg:text-[3rem]">Cronogramas</h3>
                    </div>
                    
                    <LineChart
                            :height="mobile ? 300 : undefined"
                            :percent="true"
                            :hide-projection="true"
                            :titleLabel="true"
                            :dataSets="[
                                {
                                    label: 'Projeção de Vendas',
                                    data: calculadora?.vendas_projecoes,
                                },
                                {
                                    label: 'Projeção de Obra',
                                    data: calculadora?.obra_projecoes,
                                    borderColor: colors.lightBlue,
                                    backgroundColor: colors.lightBlue,
                                }
                            ]"
                            unit="month"
                            :min="null"
                            :max="null"
                            :displayYInfo="true"
                            :showYLabel="true"

                        />
                </div>
            </section>
        </template>
        <template v-else>
            <div class="skeleton w-full h-[30rem] rounded-3xl lg:h-[58rem]"></div>
        </template>

        <template id="viabilidade" v-if="calculadora">
            <section class="flex flex-col gap-[3rem]" v-if="calculadora?.fluxo_de_caixa">
                <div class="flex justify-between">
                    <h3 class="font-medium text-[2.5rem] dark-blue lg:text-[3rem]">Viabilidade Financeira</h3>
                </div>
                <div class="rounded-2xl flex flex-col lg:flex-row text-[1.5rem]">
                    <div class="flex flex-col gap-[1rem] lg:gap-[.5rem] lg:flex-row lg:flex-wrap">
                        <div class="light-grey-bg rounded-2xl p-[1.5rem_2rem] order-2 lg:w-[49.7%] lg:order-first">
                            <div class="flex justify-between">
                                <p class="font-bold text-[1.8rem] lg:text-[2.2rem]">Receitas</p>
                            </div>
                            <div class="flex flex-col gap-[1rem] lg:gap-0 lg:text-[1.8rem]">
                                <div class="flex justify-between">
                                    <p class="font-bold">VGV Bruto</p>
                                    <p class="font-bold text-right">{{ Money(calculadora?.vgv_bruto) }}</p>
                                </div>
                                <div class="grid grid-cols-2 lg:grid-cols-3">
                                    <p class="lg:col-span-1 col-span-2 font-medium lg:font-normal">Comercializado</p>
                                    <p class="col-span-1 lg:text-right lg:pr-[30%] lg:pr-[0%] min-[1120px]:pr-[13%]"> {{ Percent(calculadora?.percentual_vendas) }} </p>
                                    <p class="col-span-1 text-right">{{ Money(calculadora?.total_vendido) }}</p>
                                </div>
                                <div class="grid grid-cols-2 lg:grid-cols-3">
                                    <p class="lg:col-span-1 col-span-2 font-medium lg:font-normal">Estoque</p>
                                    <p class="col-span-1 lg:text-right lg:pr-[30%] lg:pr-[0%] min-[1120px]:pr-[13%]"> {{ Percent(calculadora?.percentual_estoque) }} </p>
                                    <p class="col-span-1 text-right">{{ Money(calculadora?.valor_estoque) }}</p>
                                </div>
                                <div class="grid grid-cols-2 lg:grid-cols-3">
                                    <p class="lg:col-span-1 col-span-2 font-medium lg:font-normal">Permuta física</p>
                                    <p class="col-span-1 lg:text-right lg:pr-[30%] lg:pr-[0%] min-[1120px]:pr-[13%]"> {{ Percent(calculadora?.percentual_permuta_fisica) }} </p>
                                    <p class="col-span-1 text-right">{{ Money(calculadora?.valor_permuta_fisica) }}</p>
                                </div>
                            </div>
                            <div class="flex justify-between items-center border-black border-t  lg:text-[1.8rem]">
                                <p class="font-bold">VGV Líquido <br class="block min-[400px]:hidden">de Permuta</p>                                
                                <p class="font-bold">{{ Money(calculadora?.vgv_liquido_de_permuta) }}</p>
                            </div>
                        </div>

                        <div class="light-grey-bg rounded-2xl p-[1.5rem_2rem] order-3 lg:w-[49.7%] lg:order-3">
                            <div class="flex justify-between">
                                <p class="font-bold text-[1.8rem] lg:text-[2.2rem]">Abatimento de Receitas</p>
                            </div>
                            <div class="flex flex-col gap-[1rem] lg:gap-0 lg:text-[1.8rem]">
                                <div class="grid grid-cols-2 lg:grid-cols-3">
                                    <p class="lg:col-span-1 col-span-2 font-medium lg:font-normal lg:text-[1.7rem] min-[1061px]:text-[1.8rem]">Permuta financeira</p>
                                    <p class="col-span-1 lg:text-right lg:pr-[31%] lg:pr-[0%] min-[1120px]:pr-[16%]"> {{ Percent(calculadora?.percentual_permuta_financeira) }} </p>
                                    <p class="col-span-1 text-right">{{ Money(calculadora?.valor_permuta_financeira) }}</p>
                                </div>
                                <div class="grid grid-cols-2 lg:grid-cols-3">
                                    <p class="lg:col-span-1 col-span-2 font-medium lg:font-normal">Comissões</p>
                                    <p class="col-span-1 lg:text-right lg:pr-[31%] lg:pr-[0%] min-[1120px]:pr-[16%]"> {{ Percent(calculadora?.percentual_comissao) }} </p>
                                    <p class="col-span-1 text-right">{{ Money(calculadora?.valor_comissao) }}</p>
                                </div>
                                <div class="grid grid-cols-2 lg:grid-cols-3">
                                    <p class="lg:col-span-1 col-span-2 font-medium lg:font-normal">Impostos</p>
                                    <p class="col-span-1 lg:text-right lg:pr-[31%] lg:pr-[0%] min-[1120px]:pr-[16%]"> {{ Percent(calculadora?.percentual_impostos) }} </p>
                                    <p class="col-span-1 text-right">{{ Money(calculadora?.valor_impostos) }}</p>
                                </div>
                            </div>
                            <div class="flex justify-between items-center border-black border-t  lg:text-[1.8rem]">
                                <p class="font-bold">VGV Líquido</p>                                
                                <p class="font-bold">{{ Money(calculadora?.vgv_liquido) }}</p>
                            </div>
                        </div>
                        
                        <div class="light-grey-bg rounded-2xl p-[1.5rem_2rem] order-4 lg:w-[49.7%] lg:order-5">
                            <div class="flex justify-between">
                                <p class="font-bold text-[1.8rem] lg:text-[2.2rem]">Custos</p>
                            </div>
                            <div class="flex flex-col gap-[1rem] lg:gap-0 lg:text-[1.8rem]">
                                <div class="grid grid-cols-2">
                                    <p class="font-medium lg:font-normal">Terreno</p>
                                    <p class="text-right">{{ Money(calculadora?.valor_terreno) }}</p>
                                </div>
                                <div class="grid grid-cols-2">
                                    <p class="font-medium lg:font-normal">Obra</p>
                                    <p class="text-right">{{ Money(calculadora?.valor_obra) }}</p>
                                </div>
                                <div class="grid grid-cols-2">
                                    <p class="font-medium lg:font-normal">Outorga onerosa</p>
                                    <p class="text-right"> {{ Money(calculadora?.custo_outorga) }}</p>
                                </div>
                                <div class="grid grid-cols-2">
                                    <p class="font-medium lg:font-normal">Outros</p>
                                    <p class="text-right"> {{Money(calculadora?.custo_outros) }}</p>
                                </div>
                            </div>
                            <div class="flex justify-between items-center border-black border-t lg:text-[1.8rem]">
                                <p class="font-bold">Total</p>                                
                                <p class="font-bold">{{ Money(calculadora?.custo_total) }}</p>
                            </div>
                        </div>
                            

                        <div class="flex flex-col gap-[2rem] rounded-2xl order-first lg:w-[49.7%]">
                            <div class="light-grey-bg rounded-2xl p-[1.5rem_2rem]  lg:text-[1.8rem]">
                                <div class="flex justify-between">
                                    <p class="font-bold text-[1.8rem] lg:text-[2.2rem]">Ficha Técnica</p>
                                </div>
                                <div class="flex justify-between lg:mb-0">
                                    <p>Incorporadora</p>
                                    <p class="truncate max-w-[30rem]">{{ calculadora.nome_incorporadora }}</p>
                                </div>
                                <div class="flex justify-between lg:mb-0">
                                    <p>Empreendimento</p>
                                    <p class="truncate max-w-[30rem]">{{ calculadora.nome_projeto }}</p>
                                </div>
                                <div class="flex justify-between lg:mb-0">
                                    <p>Padrão</p>
                                    <p>{{ calculadora.padrao }}</p>
                                </div>
                                <div class="flex justify-between lg:mb-0">
                                    <p>É SPE?</p>
                                    <p>{{ calculadora.spe ? 'Sim' : 'Não' }}</p>
                                </div>
                                <div class="flex justify-between lg:mb-0">
                                    <br>
                                </div>                           
                            </div>
                        </div>


                        <div class="light-grey-bg rounded-2xl p-[1.5rem_2rem]  lg:text-[1.8rem] order-7 lg:w-[49.7%] lg:order-4">
                            <div class="flex justify-between">
                                <p class="font-bold text-[1.8rem] lg:text-[2.2rem]">Cronograma</p>
                            </div>
                            <div class="flex justify-between lg:mb-0">
                                <p>Lançamento</p>
                                <p>{{ ToDate(calculadora.data_lancamento) }}</p>
                            </div>
                            <div class="flex justify-between lg:mb-0">
                                <p>Fim das vendas</p>
                                <p>{{ ToDate(calculadora.data_fim_vendas) }}</p>
                            </div>
                            <div class="flex justify-between lg:mb-0">
                                <p>Início da obra</p>
                                <p>{{ ToDate(calculadora.data_inicio_obra) }}</p>
                            </div>
                            <div class="flex justify-between lg:mb-0">
                                <p>Fim da obra</p>
                                <p>{{ ToDate(calculadora.data_fim_obra) }}</p>
                            </div>                            
                        </div>


                        <div class="light-grey-bg rounded-2xl p-[1.5rem_2rem]  lg:text-[1.8rem] order-last lg:w-[49.7%]  lg:order-6">
                            <div class="flex justify-between">
                                <p class="font-bold text-[1.8rem] lg:text-[2.2rem]">Indicadores</p>
                            </div>
                            <div class="flex justify-between lg:mb-0">
                                <p class="flex gap-[.5rem]">
                                    Margem líquida
                                    <div class="relative hover-wrapper">
                                    <img :src="infoIcon" class="w-5 h-5"/>
                                        <div class="hover-info indicadores w-[20rem]">
                                            Lucro / VGV Líquido de Permuta
                                        </div>
                                    </div>
                                </p>
                                <p>{{ Percent(calculadora.margem_liquida) }}</p>
                            </div>
                            <div class="flex justify-between lg:mb-0">
                                <p class="flex gap-[.5rem]">
                                    ROC
                                    <div class="relative hover-wrapper">
                                    <img :src="infoIcon" class="w-5 h-5"/>
                                        <div class="hover-info indicadores w-[15rem]">
                                            Lucro / Custo Total
                                        </div>
                                    </div>
                                </p>
                                <p>{{ Percent(calculadora.roc) }}</p>
                            </div>
                            <div class="flex justify-between lg:mb-0">
                                <p class="flex gap-[.5rem]">
                                    ROI
                                    <div class="relative hover-wrapper">
                                    <img :src="infoIcon" class="w-5 h-5"/>
                                        <div class="hover-info indicadores w-[18rem] lg:w-[20rem]">
                                            Lucro / Exposição de Caixa
                                        </div>
                                    </div>
                                </p>
                                <p>{{ calculadora.roi_exposicao_de_caixa ? Percent(calculadora.roi_exposicao_de_caixa) : '-' }}</p>
                            </div>
                            <div class="flex justify-between lg:mb-0">
                                <p class="flex gap-[.5rem]">
                                    TIR
                                    <div class="relative hover-wrapper">
                                    <img :src="infoIcon" class="w-5 h-5"/>
                                        <div class="hover-info indicadores w-[24rem]">
                                            Taxa Interna de Retorno do Incorporador
                                        </div>
                                    </div>
                                </p>
                                <p>{{ calculadora.tir ? Percent(calculadora.tir) + ' a.a' : '-' }}</p>
                            </div>

                        </div>  
                        <div class="light-grey-bg rounded-2xl flex items-center justify-between p-[1.5rem_2rem] order-5 lg:w-[49.7%] lg:order-7">
                            <p class="font-bold text-[1.8rem] lg:text-[2.2rem]">Lucro</p>
                            <p class="font-bold text-[1.5rem] lg:text-[1.8rem]">{{ Money(calculadora?.lucro) }}</p>
                        </div>
                        <div class="light-grey-bg rounded-2xl flex items-center justify-between p-[1.5rem_2rem] order-6 lg:w-[49.7%] lg:order-last">
                            <p class="font-bold text-[1.8rem] lg:text-[2.2rem]">Exposição de caixa</p>
                            <p class="font-bold text-[1.5rem] lg:text-[1.8rem]">{{ Money(calculadora?.exposicao_de_caixa) }}</p>
                        </div> 
                    </div>
                </div>
                
            </section>
        </template>
        <template v-else>
            <div class="flex flex-col gap-[1rem] lg:grid lg:grid-cols-2">
                <div class="flex flex-col gap-[1rem]">
                    <div class="skeleton w-full h-[17rem] rounded-2xl"></div>
                    <div class="skeleton w-full h-[17rem] rounded-2xl"></div>
                    <div class="skeleton w-full h-[25rem] rounded-2xl"></div>
                </div>
                <div class="flex flex-col gap-[1rem]">
                    <div class="skeleton w-full h-[17rem] rounded-2xl"></div>
                    <div class="skeleton w-full h-[17rem] rounded-2xl"></div>
                    <div class="skeleton w-full h-[25rem] rounded-2xl"></div>
                </div>
            </div>
            <div class="flex flex-col gap-[1rem] lg:flex-row mt-[-4rem]">
                <div class="skeleton w-full h-[5rem] rounded-2xl"></div>
                <div class="skeleton w-full h-[5rem] rounded-2xl"></div>
            </div>
        </template>

        <template id="fluxo_de_caixa" v-if="calculadora">
            <section v-if="calculadora?.fluxo_de_caixa" class="pb-[4rem]">
                <div class="flex flex-col gap-[3rem]">
                    <div class="flex justify-between">
                        <h3 class="font-medium text-[2.5rem] dark-blue lg:text-[3rem]">Fluxo de Caixa Projetado</h3>
                    </div>
                    <LineChart
                        :zeroLine="true"
                        :min="null"
                        :max="null"
                        :showYLabel="true"
                        :height="mobile ? 300 : undefined"
                        :dataSets="[
                            {
                                label: 'Projeção',
                                data: calculadora.fluxo_de_caixa.grafico_fluxo_de_caixa_projetado,
                                curve: true,
                                dashed: true,
                                backgroundColor: colors.lightBlue,
                                conditionalColor: true,
                            }
                        ]"
                        :displayYInfo="true"
                        unit="month"
                    />
                </div>
                
            </section> 
        </template>
        <template v-else>
            <div class="skeleton w-full h-[30rem] rounded-3xl lg:h-[58rem]"></div>
        </template>
    </div>


</template>