import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from '@/views/website/Home.vue';
import Investidor from '@/views/website/PaginaInvestidor.vue';
import Incorporador from '@/views/website/PaginaIncorporador.vue';
import Ajuda from '@/views/website/Ajuda.vue';
import PaginaProjeto from '@/views/website/PaginaProjeto.vue';
import PaginaInvestimento from '@/views/website/PaginaInvestimento.vue';
import Login from '@/views/website/Login.vue';
import PaginaCalculadora from '@/views/website/PaginaCalculadora.vue';
import NotFound from '@/views/website/NotFound.vue'
import { authentication } from "@/services/Authentication";
import PaginaRating from "@/views/website/PaginaRating.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/landing/",
    name: "Home",
    component: Home
  },
  {
    path: "/investidor/",
    name: "Investidor",
    component: Investidor
  },
  {
    path: "/incorporador/",
    name: "Incorporador",
    component: Incorporador,
    props: true
  },
  {
    path: "/ajuda/",
    name: "Ajuda",
    component: Ajuda
  },
  {
    path: "/calculadora/",
    name: "Calculadora",
    component: PaginaCalculadora,
  },
  {
    path: "/calculadora/rating/:hashRating/",
    name: "Rating",
    component: PaginaRating,
  },
  {
    path: "/incorporador/:incorporadoraSlug/:projetoSlug",
    name: "Projeto",
    component: PaginaProjeto,
  },
  {
    path: "/investimento/:incorporadoraSlug/:projetoSlug/:captacaoSlug",
    name: "Investimento",
    component: PaginaInvestimento,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      noTemplate: true
    },
    props: true,
  }
];

const router = createRouter({
  history: createWebHistory('/'),
  routes
});

router.beforeEach((to, from) => {
  if (to.meta.requiresAuth && !authentication.isAuthenticated()) {
    return {
      name: "Login",
      query: {
        next: to.path,
        internal: 'true'
      }
    }
  }

  if (to.name == 'Login') {
    to.query.next = from.path;
    to.query.internal = 'true';
  }
})

export default router;