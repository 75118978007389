<script lang="ts">
import GeneralGateway from '@/infra/gateway/generalGateway';
import { inject } from 'vue';
import { Money, Percent } from '@/utils/formats'
import infoIcon from '@/assets/info-icon.svg';
import urbeWhite from '@/assets/urbeme_blue.svg';
import ModalService from "@/services/ModalService";
import CardRatingIncorporadora from "@/Components/CardRatingIncorporadora.vue";
import CardRatingProjeto from "@/Components/CardRatingProjeto.vue";
import copyPaste from '@/assets/copy.svg';
import { isMobile } from '@/services/Mobile';
import Loading from '@/Components/Loading.vue'
import { getOffset } from '@/services/Browser';
import arrowIcon from '@/assets/arrow.png';

export default {
    name: "PaginaProjeto",
    data() {
        return {
            calculadora: undefined as undefined,
            Money,
            Percent,
            mobile: isMobile(),
            getOffset,

            infoIcon,
            arrowIcon,
            urbeWhite,
            copyPaste,
            pasteClicked: false,
        };
    },
    components: {
        CardRatingIncorporadora,
        CardRatingProjeto,
        Loading,
    },
    setup() {
        return {
            generalGateway: inject<GeneralGateway>('generalGateway')!,
        }
    },
    methods: {
        async getDadosCalculadora() {
            let hashRating = String(this.$route.params.hashRating);
            const response = await this.generalGateway.getDadosCalculadora(hashRating); // hashRating
            this.calculadora = response;
        },
        abrirModal() {
            ModalService.mensagemComTitulo('O Rating de Crédito é uma metodologia desenvolvida para medir o risco das empresas interessadas em captar recursos na plataforma, sendo expresso pelas 9 categorias a seguir: A+, A, A-, B+, B, B-, C+, C ou C-. Quanto maior o Rating, menor será a taxa de juros proposta para as operações estruturadas.', 'Rating de Crédito')
        },
        async pasteClick() {
            await navigator.clipboard.writeText(`https://urbe.me/calculadora/rating/${this.$route.params.hashRating}`);
            this.pasteClicked = true;
            setTimeout(() => {
                this.pasteClicked = false;
            }, 1500);
        },
        scrollToDetails() {
            const element = this.$refs.calculadora;
            const top = this.getOffset((element as HTMLElement)).top;
            window.scroll({
                top: top - 80,
                behavior: 'smooth'
            });
        },
    },
    async mounted() {
        this.getDadosCalculadora();

    },
    
}

</script>

<template>
    <div class="bg-white">
        <template v-if="calculadora">
            <div class="ctn home-container" :class="mobile ? 'py-[7rem]' : 'pt-[2rem]'">
                <div class="flex flex-col gap-[1rem] lg:flex-row lg:items-center lg:justify-between">
                    <h3 class="text-[4rem] font-medium">Calculadora</h3>
                    <div @click="pasteClick" class="rounded-full cursor-pointer light-blue-bg h-[4rem] flex justify-center gap-[1rem] items-center w-[16rem]">
                        <a target="_blank" class="text-[1.8rem] text-slate-800">{{pasteClicked ? 'Copiado' : 'Copiar Link'}}</a>
                        <img v-if="!pasteClicked" :src="copyPaste" class="h-[2rem] filter-dark-blue"/>
                    </div>
                </div>
                <div :class="mobile ? 'h-[calc(100vh-20rem)]' : 'h-[calc(100vh-8rem)]'" class="mt-[2rem] flex md:justify-between lg:items-center p-[4rem_4rem] bg-[rgba(255,255,255,.18)] rounded-[16px] shadow-[0_4px_30px_rgba(0,0,0,.1)] border-[1px_solid_rgba(255,255,255,.14)]">
                    <div class="relative flex flex-col lg:flex-row w-full justify-around">
                        <div class="flex flex-col w-full lg:w-1/2 gap-[4rem] lg:gap-[6rem]">
                            <div class="flex flex-col text-center" data-aos-duration="1000">
                                <p class="text-[1.6rem]">Incorporadora</p>
                                <p class="font-medium text-[3.2rem] lg:text-[4rem] lg:truncate" data-aos="zoom-in">{{ calculadora?.incorporadora?.nome_incorporadora }}</p>
                            </div>
    
                            <p v-if="calculadora?.projeto" class="font-medium text-[5rem] text-center" data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="1000"> + </p>
    
                            <div v-if="calculadora?.projeto" class="flex flex-col text-center" data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="1300">
                                <p class="text-[1.6rem]">Empreendimento</p>
                                <p class="font-medium text-[3.2rem] lg:text-[4rem] lg:truncate"> {{ calculadora?.projeto?.nome_projeto }}</p>
                            </div>
                        </div>
                        <div class="flex flex-col w-full lg:w-1/2 items-center lg:justify-center" data-aos="zoom-in"  data-aos-duration="1000" :data-aos-delay="calculadora.projeto ? '2400' : '1600'">
                            <div class="flex flex-col">
                                <p class="text-[1.6rem]">Rating</p>
                                <p class="font-medium text-[6rem]"> {{ calculadora?.conceito_final }}</p>
                            </div>
                        </div>
                        <div
                            :class="calculadora.projeto ? 'bottom-[1%] lg:bottom-[-40%] right-0 lg:right-[2%]' : 'bottom-[4%] lg:bottom-[-200%] right-0 lg:right-[2%]'"
                            @click="scrollToDetails"
                            class="absolute h-[6rem] w-[6rem] dark-blue-bg rounded-full cursor-pointer">
                            <div class="flex mt-[1rem] justify-center">
                                <img :src="arrowIcon" class="filter-white rotate-[360deg] h-[4rem] w-[4rem]"/>
                            </div>
                        </div>
                    </div>
                </div>
    
                <div ref="calculadora" class="flex flex-col mt-[4rem]">
                    <CardRatingIncorporadora
                        :calculadora="calculadora.incorporadora" />
                    
                    <CardRatingProjeto
                        v-if="calculadora.projeto"
                        :calculadora="calculadora.projeto" />
                </div>
            </div>
        </template>
        <template v-else>
            <div class="h-screen mx-auto">
                <Loading :size="8"/>
            </div>
        </template>
    </div>
</template>

<style scoped>
</style>
