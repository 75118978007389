<script lang="ts">
import SubmitButton from '@/Components/SubmitButton.vue';
import InputField from './InputField.vue';
import { PropType, defineComponent } from 'vue';
import { InputType } from "@/Types/InputTypes";
import Clipboard from '@/assets/Clipboard.svg';
import { Processing } from '@/Types/General';


export default defineComponent({
    name: 'CardInputToken',
    props: {
        title: {type: String, default: ''},
        errorMessage: {type: String, default: ''},
        processing: {type: Object as PropType<Processing>, default: {} as Processing}
    },
    components: {
        SubmitButton,
        InputField,
    },
    data() {
        return {
            InputType,
            Clipboard,
            token: '',

            btnPasteClicked: false,
        }
    },
    emits: [
        'callback',
    ],
    methods:{
        handleCallback() {
            
            this.$emit('callback', this.token)
        
        },
        async pasteClick() {
            this.btnPasteClicked = !this.btnPasteClicked;
        }
    }
})
</script>

<template>
    <div class="bg-white h-screen md:h-[calc(100vh-6rem)] flex flex-col justify-center items-center">
        <div class="flex flex-col gap-[1.5rem]" data-aos="zoom-in" data-aos-duration="1000">
            <h2 class="text-[2.5rem] text-center">{{ title }}</h2>
            <div class="flex justify-center">
                <InputField token-classes="text-[3rem] rounded-lg" :type="InputType.TOKEN" v-model:value="token" :pasteClick="btnPasteClicked" :inputsToken="6" name=""/>
            </div>
            <template v-if="!processing.getValue() && errorMessage">
                <p class="text-inadimplente text-center">{{ errorMessage }}</p>
            </template>
            <div class="flex justify-around">
                <button class="hover:underline font-medium flex items-center justify-center" @click="pasteClick">Colar <img :src="Clipboard" class="filter-dark ml-2" /></button>
                
                <SubmitButton 
                :no-padding="true"
                :noBorder="true"
                :error="false"
                text="Enviar"
                classes="text-white font-medium dark-blue-bg rounded-[.5rem]"
                :disabled="processing.getValue()"
                :onClickAction="handleCallback" />
            </div>
            
        </div>
    </div>
</template>