<script lang="ts">

import { isMobile } from '@/services/Mobile';

export default {
    name: 'CardCaptacaoContainer',
    props: {
        bgImage: {type: String, default: ''},
        logoIncorporadora: {type: String, default: ''},
        nomeProjeto: {type: String, defailt: ''},
        cidadeProjeto: {type: String, default: ''},
        captacoesAbertas: {type: Boolean, default: false},
        tipo: {type: String, default: 'oferta'},
    },
    data() {
        return {
            zoom: 0,
            isMobile
        }
    },
    methods: {
        backgroundParallax() {
            let el = document.getElementsByClassName("background-parallax")[0];
            if(!el) return;
            const speed = 0.1;

            const windowYOffset = window.pageYOffset;   
                         
            if ((el.offsetTop-screen.height) < windowYOffset) {
                const elBackgrounPos = "center " + `calc(50% + ${((windowYOffset-el.offsetTop) * speed)}px)`;
                el.style.backgroundPosition = elBackgrounPos;
            }
        },
        getDevicePixelRatio() {
            this.zoom = window.devicePixelRatio;
        }
    },
    mounted() {
        window.onscroll = () => {
            !isMobile() && this.backgroundParallax();
        }
        this.getDevicePixelRatio();
    }
}
</script>

<template>
    <div class="flex flex-col background-parallax bg-no-repeat md:bg-fixed" :class="tipo == 'projeto' ? (captacoesAbertas ? 'h-full md:h-[calc(100vh-7rem)]' : 'h-screen md:h-[calc(100vh-7rem)]') : ''" :style="`background-image: url('${bgImage}');`">
        <div style="background-color: rgba(0,0,0,.2);" :class="tipo == 'projeto' ? (captacoesAbertas ? 'h-full md:h-[calc(100vh-7rem)]' : 'h-screen md:h-[calc(100vh-7rem)]') : ''">
        <template v-if="tipo == 'projeto'">
            <div class="ctn home-container pb-[3rem]" :class="tipo == 'projeto' ? (captacoesAbertas ? 'md:flex md:flex-col md:gap-[2rem]' : 'flex flex-col gap-[10rem] md:gap-[30rem]') : ''">
                <div class="filter-white items-end flex" :class="tipo == 'projeto' ? (captacoesAbertas ? `h-[15rem] mt-[2rem] md:mt-0 ${(zoom == 1 ? 'md:h-[30rem]' : 'md:h-[17rem]')}` : 'h-[27rem]') : ''">
                    <img :src="logoIncorporadora" class="w-auto h-auto max-w-[16rem] max-h-[10rem] md:max-h-[16rem]">
                </div>    
                <div class="flex flex-col md:items-center md:justify-between md:flex-row">
                    <h1 class="text-white text-[5rem] md:text-[6rem] font-medium">{{ nomeProjeto }}</h1>
                    <p class="text-white mt-[3rem] text-[1.8rem]"><strong>Cidade</strong> &nbsp; {{ cidadeProjeto }}</p>
                </div>
                <slot />
            </div>
        </template>
        <template v-else>
            <div class="ctn home-container">
                <slot/>
            </div>
        </template>
        </div>
    </div>
</template>
