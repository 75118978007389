<script lang="ts">
import ModalService from "@/services/ModalService";
import { Money, Percent } from "@/utils/formats";
import { isMobileApp } from "@/services/Mobile";
import infoIcon from '@/assets/info-icon.svg';
import DownloadButton from "./DownloadButton.vue";
import { Processing } from "@/Types/General";
import aludeIcon from "@/assets/alude.svg";


export default {
    name: 'CardIncorporadora',
    props: {
        dadosProjeto: {type: Object, default: undefined},
    },
    components: {
        DownloadButton,
    },
    data() {
        return {
            Percent,
            Money,
            mobileApp: isMobileApp(),
            
            processing: new Processing(),
            aludeIcon,
            infoIcon,
        }
    },
    methods: {
        abrirModal() {
            ModalService.mensagemComTitulo('O Rating de Crédito é uma metodologia desenvolvida para medir o risco das empresas interessadas em captar recursos na plataforma, sendo expresso pelas 9 categorias a seguir: A+, A, A-, B+, B, B-, C+, C ou C-. Quanto maior o Rating, menor será a taxa de juros proposta para as operações estruturadas.', 'Rating de Crédito')
        }
    },
}
</script>

<template>
    <div class="ctn home-container py-[6rem]">
        <div v-if="dadosProjeto" class="flex flex-col gap-[3rem] lg:flex-row lg:justify-between lg:items-center">
            <div class="flex flex-col gap-[3rem] md:flex-row md:gap-[10rem] md:items-center">
                <h2 class="font-medium text-[3rem] lg:text-[3.6rem]">O Incorporador</h2>
                <img class="max-w-[14rem] max-h-[8rem] filter-dark-blue lg:max-w-[16rem] lg:max-h-[8rem]" :src="dadosProjeto?.logo_incorporadora"/>
                <div class="flex">
                    <p class="text-[1.8rem] grey-label font-medium">Sede &nbsp;</p>
                    <p class="text-[1.8rem] font-medium">{{ dadosProjeto?.sede_incorporadora }}</p>
                </div>
            </div>
            <div v-if="!mobileApp" class="rounded-full light-blue-bg h-[4rem] flex justify-center items-center w-[14rem]">
                <a target="_blank" class="text-[1.8rem] text-slate-800" :href="dadosProjeto?.site_incorporadora">Conhecer +</a>
            </div>
        </div>
        <div v-else class="flex flex-col gap-[3rem] lg:gap-[10rem] lg:items-center lg:flex-row">
            <div class="skeleton w-[25rem] h-[2rem] rounded-3xl"></div>
            <div class="skeleton w-[15rem] h-[4rem] rounded-2xl"></div>
            <div class="skeleton w-[20rem] h-[2rem] rounded-3xl"></div>
            <div class="skeleton rounded-full w-[15rem] h-[3rem] lg:ml-auto lg:mr-0"></div>
        </div>

        <div v-if="dadosProjeto" class="border-[.1rem] border-solid border-[#001a42] my-[1.5rem]"></div>
        <div v-else></div>
        
        <div v-if="dadosProjeto" class="grid grid-cols-1 gap-[1rem] lg:flex lg:justify-between min-[404px]:grid-cols-2">
            <div class="flex flex-col justify-between md:justify-center">
                <p class="font-medium text-[1.8rem] grey-label">Nº de captações</p>
                <p class="text-black text-[2.8rem] md:text-[3.8rem]">{{ dadosProjeto?.numero_captacoes_incorporadora }}</p>
            </div>
            <div class="flex flex-col justify-between md:justify-center">
                <p class="font-medium text-[1.8rem] grey-label">Total captado</p>
                <p class="text-black text-[2.8rem] md:text-[3.8rem]">{{ dadosProjeto?.total_captado_incorporadora == 0 ? '-' : Money(dadosProjeto?.total_captado_incorporadora, true, true) }}</p>
            </div>
            <div class="flex flex-col justify-between md:justify-center">
                <p class="font-medium text-[1.8rem] grey-label">Adimplência</p>
                <p class="text-black text-[2.8rem] md:text-[3.8rem]" :class="dadosProjeto?.adimplencia_incorporadora == '-' ? '' : 'font-bold'">{{ dadosProjeto?.adimplencia_incorporadora == '-' ? dadosProjeto?.adimplencia_incorporadora : Percent(dadosProjeto?.adimplencia_incorporadora) }}</p>
            </div>
            <div class="flex flex-col justify-between md:justify-center">
                <p class="font-medium text-[1.8rem] grey-label">Rentabilidade média</p>
                <p class="text-black text-[2.8rem] md:text-[3.8rem]">{{ dadosProjeto?.rentabilidade_media_incorporadora == '-' ? dadosProjeto?.rentabilidade_media_incorporadora : Percent(dadosProjeto?.rentabilidade_media_incorporadora / 100) + ' a.a' }}</p>
            </div>
            <div class="flex flex-col justify-between md:justify-center">
                <p class="font-medium text-[1.8rem] grey-label">Rating de crédito <i class="fas fa-question-circle filter-dark-blue hover:opacity-70 pointer" style="transform: scale(0.66);" @click="abrirModal"></i></p>
                <p class="text-black text-[2.8rem] md:text-[3.8rem]">{{ dadosProjeto?.rating_incorporadora }}</p>
            </div>
        </div>
        <div v-else class="mt-[2rem] grid grid-cols-2 gap-[1rem] lg:flex lg:justify-between">
            <div class="skeleton h-[10rem] rounded-3xl lg:flex lg:w-full"></div>
            <div class="skeleton h-[10rem] rounded-3xl lg:flex lg:w-full"></div>
            <div class="skeleton h-[10rem] rounded-3xl lg:flex lg:w-full"></div>
            <div class="skeleton h-[10rem] rounded-3xl lg:flex lg:w-full"></div>
            <div class="skeleton h-[10rem] rounded-3xl lg:flex lg:w-full"></div>
        </div>

        <div id="analise-financeira" class="mt-[5rem]">
            <section class="flex flex-col gap-[3rem]">
                    <div class="flex justify-between">
                        <h3 class="font-medium text-[2.5rem] dark-blue md:text-[3rem]">Balanço Financeiro <span class="underline">{{dadosProjeto?.dados_report.periodo_contabil}}/{{ dadosProjeto?.dados_report.ano_contabil }}</span></h3>
                        <a v-if="dadosProjeto?.balanco_patrimonial_incorporadora" :href="dadosProjeto?.balanco_patrimonial_incorporadora" download target="_blank"><DownloadButton :text="true" :loading="processing.getValue()"  width="w-[3.5rem] md:w-[4.5rem]" height="h-full" backgroundColor="filter-dark-blue" classes="mr-[1.5rem]"/>
</a>
                    </div>
                    <div class="rounded-2xl flex flex-col lg:flex-row text-[1.5rem]">
                        <div class="flex flex-col gap-[1rem] lg:gap-[.5rem] lg:flex-row lg:flex-wrap">
                            <div class="light-grey-bg rounded-2xl p-[1.5rem_2rem] md:text-[1.8rem] lg:w-[49.7%]">
                                <div class="flex justify-between">
                                    <p class="font-bold text-[1.8rem] md:text-[2.2rem]">Ativos</p>
                                </div>
                                <div class="flex justify-between md:mb-0">
                                    <p>Caixa e Disponibilidades</p>
                                    <p>{{Money(dadosProjeto?.dados_report.caixa_e_aplicacoes_financeiras)}}</p>
                                </div>
                                <div class="flex justify-between md:mb-0">
                                    <p>Circulante Total</p>
                                    <p>{{Money(dadosProjeto?.dados_report.ativo_circulante)}}</p>
                                </div>
                                <div class="flex justify-between md:mb-0">
                                    <p>Estoque (imóveis a comercializar)</p>
                                    <p>{{Money(dadosProjeto?.dados_report.estoques)}}</p>
                                </div>
                                <div class="flex justify-between md:mb-0">
                                    <p>Não Circulante Total</p>
                                    <p>{{Money(dadosProjeto?.dados_report.ativo_nao_circulante)}}</p>
                                </div> 
                            </div>

                            <div class="light-grey-bg rounded-2xl p-[1.5rem_2rem] md:text-[1.8rem] lg:w-[49.7%]">
                                <div class="flex justify-between">
                                    <p class="font-bold text-[1.8rem] md:text-[2.2rem]">Passivos</p>
                                </div>
                                <div class="flex justify-between md:mb-0">
                                    <p>Circulante Total</p>
                                    <p>{{Money(dadosProjeto?.dados_report.passivo_circulante)}}</p>
                                </div>
                                <div class="flex justify-between md:mb-0">
                                    <p>Empréstimos e Financiamentos</p>
                                    <p>{{Money(dadosProjeto?.dados_report.financiamentos)}}</p>
                                </div>
                                <div class="flex justify-between md:mb-0">
                                    <p>Não Circulante Total</p>
                                    <p>{{Money(dadosProjeto?.dados_report.passivo_nao_circulante)}}</p>
                                </div>
                                <div class="flex justify-between md:mb-0">
                                    <p>Patrimônio Líquido</p>
                                    <p>{{Money(dadosProjeto?.dados_report.patrimonio_liquido)}}</p>
                                </div> 
                            </div>

                            <div class="light-grey-bg rounded-2xl flex items-center justify-between p-[1.5rem_2rem] order-5 lg:w-[49.7%] lg:order-7">
                                <p class="font-bold text-[1.8rem] md:text-[2.2rem]">Faturamento Bruto</p>
                                <p class="font-bold text-[1.5rem] md:text-[1.8rem]">{{Money(dadosProjeto?.dados_report.receita_bruta)}}</p>
                            </div>
                            <div class="light-grey-bg rounded-2xl flex items-center justify-between p-[1.5rem_2rem] order-6 lg:w-[49.7%] lg:order-last">
                                <p class="font-bold text-[1.8rem] md:text-[2.2rem]">Lucro Líquido</p>
                                <p class="font-bold text-[1.5rem] md:text-[1.8rem]">{{Money(dadosProjeto?.dados_report.lucro_liquido)}}</p>
                            </div> 
                        </div>
                    
                    </div>
                    
            </section>
        </div>


        <div id="indicadores" class="mt-[5rem]">
            <section class="flex flex-col gap-[3rem]">
                    <div class="flex">
                        <h3 class="font-medium text-[2.5rem] dark-blue md:text-[3rem]">Indicadores Financeiros</h3>
                    </div>
                    <div class="rounded-2xl flex flex-col lg:flex-row text-[1.5rem]">
                        <div class="flex flex-col gap-[1rem] lg:gap-[.5rem] lg:flex-row lg:flex-wrap">
                            <div class="light-grey-bg rounded-2xl p-[1.5rem_2rem] md:text-[1.8rem] lg:w-[49.7%]">
                                <div class="flex justify-between">
                                    <p class="font-bold text-[1.8rem] md:text-[2.2rem]">Liquidez</p>
                                </div>
                                <div class="flex justify-between md:mb-0">
                                    <p class="flex gap-[.5rem]">
                                        Imediata
                                        <div class="relative hover-wrapper">
                                            <img :src="infoIcon" class="w-5 h-5"/>
                                            <div class="hover-info indicadores w-[28rem]">
                                                Caixa e Disponibilidades / Passivo Circulante
                                            </div>
                                        </div>
                                    </p>
                                    <p class="flex gap-[2rem]">
                                        <span>{{Percent(dadosProjeto?.dados_report.imediata.valor)}}</span>
                                         <div class="flex justify-between w-[4rem]">
                                            <span class="font-bold">{{ dadosProjeto?.dados_report.imediata.conceito}}</span>
                                            <div class="relative hover-wrapper">
                                                <img :src="infoIcon" class="w-5 h-5"/>
                                                <div class="hover-info indicadores right w-[18rem]">
                                                    C- &lt; {{ Percent(dadosProjeto?.dados_report.imediata.menor_nota)}} e A+ &gt; {{ Percent(dadosProjeto?.dados_report.imediata.maior_nota) }}
                                                </div>
                                            </div>
                                        </div>
                                    </p>
                                </div>
                                <div class="flex justify-between md:mb-0">
                                    <p class="flex gap-[.5rem]">
                                        Seca
                                    <div class="relative hover-wrapper">
                                        <img :src="infoIcon" class="w-5 h-5"/>
                                        <div class="hover-info indicadores w-[24rem]">
                                            Ativo Circulante / Passivo Circulante
                                        </div>
                                    </div>
                                    </p>
                                    <p class="flex gap-[2rem]">
                                        <span>{{Percent(dadosProjeto?.dados_report.seca.valor)}}</span>
                                         <div class="flex justify-between w-[4rem]">
                                            <span class="font-bold">{{ dadosProjeto?.dados_report.seca.conceito}}</span>
                                            <div class="relative hover-wrapper">
                                                <img :src="infoIcon" class="w-5 h-5"/>
                                                <div class="hover-info indicadores right w-[18rem]">
                                                    C- &lt; {{ Percent(dadosProjeto?.dados_report.seca.menor_nota)}} e A+ &gt; {{ Percent(dadosProjeto?.dados_report.seca.maior_nota) }}
                                                </div>
                                            </div>
                                        </div>
                                    </p>
                                </div>
                                <div class="flex justify-between md:mb-0">
                                    <p class="flex gap-[.5rem]">
                                        Corrente
                                    <div class="relative hover-wrapper">
                                        <img :src="infoIcon" class="w-5 h-5"/>
                                        <div class="hover-info indicadores w-[30rem]">
                                            (Ativo Circulante - Estoques) / Passivo Circulante
                                        </div>
                                    </div>
                                    </p>
                                    <p class="flex gap-[2rem]">
                                        <span>{{Percent(dadosProjeto?.dados_report.corrente.valor)}}</span>
                                         <div class="flex justify-between w-[4rem]">
                                            <span class="font-bold">{{ dadosProjeto?.dados_report.corrente.conceito}}</span>
                                            <div class="relative hover-wrapper">
                                                <img :src="infoIcon" class="w-5 h-5"/>
                                                <div class="hover-info indicadores right w-[18rem]">
                                                    C- &lt; {{ Percent(dadosProjeto?.dados_report.corrente.menor_nota)}} e A+ &gt; {{ Percent(dadosProjeto?.dados_report.corrente.maior_nota) }}
                                                </div>
                                            </div>
                                        </div>
                                    </p>
                                </div>
                            </div>

                            <div class="light-grey-bg rounded-2xl p-[1.5rem_2rem] md:text-[1.8rem] lg:w-[49.7%]">
                                <div class="flex justify-between">
                                    <p class="font-bold text-[1.8rem] md:text-[2.2rem]">Endividamento</p>
                                </div>
                                <div class="flex justify-between md:mb-0">
                                    <p class="flex gap-[.5rem]">
                                        Curto Prazo
                                    <div class="relative hover-wrapper">
                                        <img :src="infoIcon" class="w-5 h-5"/>
                                        <div class="hover-info indicadores w-[38rem]">
                                            Passivo Circulante / (Passivo Circulante + Passivo Não Circulante)
                                        </div>
                                    </div>
                                    </p>
                                    <p class="flex gap-[2rem]">
                                        <span>{{Percent(dadosProjeto?.dados_report.curto_prazo.valor)}}</span>
                                         <div class="flex justify-between w-[4rem]">
                                            <span class="font-bold">{{ dadosProjeto?.dados_report.curto_prazo.conceito}}</span>
                                            <div class="relative hover-wrapper">
                                                <img :src="infoIcon" class="w-5 h-5"/>
                                                <div class="hover-info indicadores right w-[18rem]">
                                                    C- &gt; {{ Percent(dadosProjeto?.dados_report.curto_prazo.menor_nota)}} e A+ &lt; {{ Percent(dadosProjeto?.dados_report.curto_prazo.maior_nota) }}
                                                </div>
                                            </div>
                                        </div>
                                    </p>
                                </div>
                                <div class="flex justify-between md:mb-0">
                                    <p class="flex gap-[.5rem]">
                                        Longo Prazo
                                    <div class="relative hover-wrapper">
                                        <img :src="infoIcon" class="w-5 h-5"/>
                                        <div class="hover-info indicadores w-[24rem]">
                                            Passivo Não Circulante / Ativo Total
                                        </div>
                                    </div>
                                    </p>
                                    <p class="flex gap-[2rem]">
                                        <span>{{Percent(dadosProjeto?.dados_report.longo_prazo.valor)}}</span>
                                         <div class="flex justify-between w-[4rem]">
                                            <span class="font-bold">{{ dadosProjeto?.dados_report.longo_prazo.conceito}}</span>
                                            <div class="relative hover-wrapper">
                                                <img :src="infoIcon" class="w-5 h-5"/>
                                                <div class="hover-info indicadores right w-[18rem]">
                                                    C- &gt; {{ Percent(dadosProjeto?.dados_report.longo_prazo.menor_nota)}} e A+ &lt; {{ Percent(dadosProjeto?.dados_report.longo_prazo.maior_nota) }}
                                                </div>
                                            </div>
                                        </div>
                                    </p>
                                </div>
                                <div class="flex justify-between md:mb-0">
                                    <p class="flex gap-[.5rem]">
                                        Geral
                                    <div class="relative hover-wrapper">
                                        <img :src="infoIcon" class="w-5 h-5"/>
                                        <div class="hover-info indicadores w-[40rem]">
                                            (Passivo Circulante + Passivo Não Circulante) / Patrimônio Líquido
                                        </div>
                                    </div>
                                    </p>
                                    <p class="flex gap-[2rem]">
                                        <span>{{Percent(dadosProjeto?.dados_report.geral.valor)}}</span>
                                         <div class="flex justify-between w-[4rem]">
                                            <span class="font-bold">{{ dadosProjeto?.dados_report.geral.conceito}}</span>
                                            <div class="relative hover-wrapper">
                                                <img :src="infoIcon" class="w-5 h-5"/>
                                                <div class="hover-info indicadores right w-[18rem]">
                                                    C- &gt; {{ Percent(dadosProjeto?.dados_report.geral.menor_nota)}} e A+ &lt; {{ Percent(dadosProjeto?.dados_report.geral.maior_nota) }}
                                                </div>
                                            </div>
                                        </div>
                                    </p>
                                </div>
                            </div>

                            <div class="light-grey-bg rounded-2xl p-[1.5rem_2rem] md:text-[1.8rem] lg:w-[49.7%]">
                                <div class="flex justify-between">
                                    <p class="font-bold text-[1.8rem] md:text-[2.2rem]">Rentabilidade</p>
                                </div>
                                <div class="flex justify-between md:mb-0">
                                    <p class="flex gap-[.5rem]">
                                        ROE
                                    <div class="relative hover-wrapper">
                                        <img :src="infoIcon" class="w-5 h-5"/>
                                        <div class="hover-info indicadores w-[22rem]">
                                            Lucro Líquido / Patrimônio Líquido
                                        </div>
                                    </div>
                                    </p>
                                    <p class="flex gap-[2rem]">
                                        <span>{{Percent(dadosProjeto?.dados_report.roe.valor)}}</span>
                                         <div class="flex justify-between w-[4rem]">
                                            <span class="font-bold">{{ dadosProjeto?.dados_report.roe.conceito}}</span>
                                            <div class="relative hover-wrapper">
                                                <img :src="infoIcon" class="w-5 h-5"/>
                                                <div class="hover-info indicadores right w-[18rem]">
                                                    C- &lt; {{ Percent(dadosProjeto?.dados_report.roe.menor_nota)}} e A+ &gt; {{ Percent(dadosProjeto?.dados_report.roe.maior_nota) }}
                                                </div>
                                            </div>
                                        </div>
                                    </p>
                                </div>
                                <div class="flex justify-between md:mb-0">
                                    <p class="flex gap-[.5rem]">
                                        Margem Líquida
                                    <div class="relative hover-wrapper">
                                        <img :src="infoIcon" class="w-5 h-5"/>
                                        <div class="hover-info indicadores w-[22rem]">
                                            Lucro Líquido / Faturamento Bruto
                                        </div>
                                    </div>
                                    </p>
                                    <p class="flex gap-[2rem]">
                                        <span>{{Percent(dadosProjeto?.dados_report.margem_liquida.valor)}}</span>
                                         <div class="flex justify-between w-[4rem]">
                                            <span class="font-bold">{{ dadosProjeto?.dados_report.margem_liquida.conceito}}</span>
                                            <div class="relative hover-wrapper">
                                                <img :src="infoIcon" class="w-5 h-5"/>
                                                <div class="hover-info indicadores right w-[18rem]">
                                                    C- &lt; {{ Percent(dadosProjeto?.dados_report.margem_liquida.menor_nota)}} e A+ &gt; {{ Percent(dadosProjeto?.dados_report.margem_liquida.maior_nota) }}
                                                </div>
                                            </div>
                                        </div>
                                    </p>
                                </div>
                                <div class="flex justify-between md:mb-0">
                                    <p class="flex gap-[.5rem]">
                                        ROIC
                                    <div class="relative hover-wrapper">
                                        <img :src="infoIcon" class="w-5 h-5"/>
                                        <div class="hover-info indicadores left w-[40rem]">
                                            Lucro Líquido / (Patrimônio Líquido - Empréstimos e Financiamentos)
                                        </div>
                                    </div>
                                    </p>
                                    <p class="flex gap-[2rem]">
                                        <span>{{Percent(dadosProjeto?.dados_report.roic.valor)}}</span>
                                         <div class="flex justify-between w-[4rem]">
                                            <span class="font-bold">{{ dadosProjeto?.dados_report.roic.conceito}}</span>
                                            <div class="relative hover-wrapper">
                                                <img :src="infoIcon" class="w-5 h-5"/>
                                                <div class="hover-info indicadores right w-[18rem]">
                                                    C- &lt; {{ Percent(dadosProjeto?.dados_report.roic.menor_nota)}} e A+ &gt; {{ Percent(dadosProjeto?.dados_report.roic.maior_nota) }}
                                                </div>
                                            </div>
                                        </div>
                                    </p>
                                </div>
                            </div>

                            <div class="light-grey-bg rounded-2xl p-[1.5rem_2rem] md:text-[1.8rem] lg:w-[49.7%]">
                                <div class="flex justify-between">
                                    <p class="font-bold text-[1.8rem] md:text-[2.2rem]">Histórico</p>
                                </div>
                                <div class="flex justify-between md:mb-0">
                                    <p class="flex gap-[.5rem]">
                                        Tempo de Fundação
                                    </p>
                                    <p class="flex gap-[2rem]">
                                        <span>{{dadosProjeto?.dados_report.tempo_de_fundacao.valor}} anos</span>
                                         <div class="flex justify-between w-[4rem]">
                                            <span class="font-bold">{{ dadosProjeto?.dados_report.tempo_de_fundacao.conceito}}</span>
                                            <div class="relative hover-wrapper">
                                                <img :src="infoIcon" class="w-5 h-5"/>
                                                <div class="hover-info indicadores right w-[14rem]">
                                                    C- &lt; {{ dadosProjeto?.dados_report.tempo_de_fundacao.menor_nota}} e A+ &gt; {{ dadosProjeto?.dados_report.tempo_de_fundacao.maior_nota }} anos
                                                </div>
                                            </div>
                                        </div>
                                    </p>
                                </div>
                                <div class="flex justify-between md:mb-0">
                                    <p class="flex gap-[.5rem]">
                                        Obras Entregues
                                    <div class="relative hover-wrapper">
                                        <img :src="infoIcon" class="w-5 h-5"/>
                                        <div class="hover-info indicadores w-[28rem]">
                                            Quantidade de empreendimentos concluídos
                                        </div>
                                    </div>
                                    </p>
                                    <p class="flex gap-[2rem]">
                                        <span>{{dadosProjeto?.dados_report.obras_entregues.valor}} obras</span>
                                        <div class="flex justify-between w-[4rem]">
                                            <span class="font-bold">{{ dadosProjeto?.dados_report.obras_entregues.conceito}}</span>
                                            <div class="relative hover-wrapper">
                                                <img :src="infoIcon" class="w-5 h-5"/>
                                                <div class="hover-info indicadores right w-[14rem]">
                                                    C- &lt; {{ dadosProjeto?.dados_report.obras_entregues.menor_nota}} e A+ &gt; {{ dadosProjeto?.dados_report.obras_entregues.maior_nota }} obras
                                                </div>
                                            </div>
                                        </div>
                                    </p>
                                </div>
                                <div class="flex justify-between md:mb-0">
                                    <p class="flex gap-[.5rem]">
                                        Experiência Executivos
                                    <div class="relative hover-wrapper">
                                        <img :src="infoIcon" class="w-5 h-5"/>
                                        <div class="hover-info indicadores w-[32rem]">
                                            Média de anos de experiência dos principais sócios
                                        </div>
                                    </div>
                                    </p>
                                    <p class="flex gap-[2rem]">
                                        <span>{{dadosProjeto?.dados_report.experiencia_executivos.valor}} anos</span>
                                         <div class="flex justify-between w-[4rem]">
                                            <span class="font-bold">{{ dadosProjeto?.dados_report.experiencia_executivos.conceito}}</span>
                                            <div class="relative hover-wrapper">
                                                <img :src="infoIcon" class="w-5 h-5"/>
                                                <div class="hover-info indicadores right w-[14rem]">
                                                    C- &lt; {{ dadosProjeto?.dados_report.experiencia_executivos.menor_nota}} e A+ &gt; {{ dadosProjeto?.dados_report.experiencia_executivos.maior_nota }} anos
                                                </div>
                                            </div>
                                        </div>
                                    </p>
                                </div>
                            </div>
                            <div class="light-grey-bg rounded-2xl flex items-center justify-between p-[1.5rem_2rem] order-5 w-full lg:order-7">
                                <p class="font-bold text-[1.8rem] md:text-[2.2rem] flex">
                                    Rating da Incorporadora 
                                    <div class="relative hover-wrapper">
                                        <img :src="infoIcon" class="w-5 h-5"/>
                                        <div class="hover-info indicadores w-[48rem] font-normal">
                                            Média dos indicadores listados acima, ponderados por pesos definidos pela URBE.ME
                                        </div>
                                    </div>
                                    <span class="underline ml-[1rem]">{{dadosProjeto?.dados_report.periodo_contabil}}/{{ dadosProjeto?.dados_report.ano_contabil }}</span></p>
                                <p class="font-bold text-[1.5rem] md:text-[1.8rem]">
                                    <span class="bg-[rgba(0,22,66,1)] p-[.5rem_1rem] text-white rounded-xl ml-[2rem] ">{{dadosProjeto?.rating_incorporadora}}</span>
                                </p>
                            </div>
                        </div>
                    
                    </div>
                    
            </section>

            <div v-if="dadosProjeto?.relatorio_alude_incorporadora" id="documentos_incorporadora" class="mt-[5rem]">
                <section class="flex flex-col gap-[3rem]">
                        <div class="flex gap-[1rem] items-center">
                            <h3 class="font-medium text-[2.5rem] dark-blue md:text-[3rem]">Documentos</h3>
                        </div>
                        <div v-if="dadosProjeto?.relatorio_alude_incorporadora" class="light-grey-bg rounded-2xl flex items-center justify-between p-[1.5rem_2rem] order-5 w-full lg:order-7">
                            <div class="flex gap-[.5rem] items-center">
                                <img class="filter-dark-blue h-[1.3rem] md:h-[1.6rem]" :src="aludeIcon" />
                                <p class="font-bold text-[1.8rem] md:text-[2.2rem] flex">- Análise de Ficha</p>
                            </div>
                            <a v-if="dadosProjeto?.relatorio_alude_incorporadora" :href="dadosProjeto?.relatorio_alude_incorporadora" download target="_blank">
                                <DownloadButton :text="true" :loading="processing.getValue()"  width="w-[3.5rem] md:w-[4.5rem]" height="h-full" backgroundColor="filter-dark-blue" classes="mr-[1.5rem]"/>
                            </a>
                        </div>
                </section>
            </div>
        </div>

       
    </div>

</template>