<script lang="ts">
import { defineComponent, PropType } from 'vue';
import InputField from '@/Components/InputField.vue';
import { InputType } from '@/Types/InputTypes';
import { ICalculadoraStep8 } from '@/Types/Calculadora'
import { MaskType } from '@/Types/MaskTypes';
import { Money } from '@/utils/formats'


export default defineComponent({
    name: "FormularioCalculadoraStep8",
    components: {
        InputField,
    },
    props: {
        calculadora: {type: Object as PropType<ICalculadoraStep8>, required: true},
        editable: {type: Boolean},
    },
    data() {
        return {
            InputType,
            MaskType,

            Money,
        }
    },
})
</script>

<template>
    <div class="step flex flex-col gap-[1rem]" id="step-3">
        <div>
            <p class="font-bold">Passo 8: Custos</p>
        </div>

        <div class="grid grid-cols md:grid-cols-2">
            <InputField
                name="custo_terreno"
                :is-required="true"
                v-model:value="calculadora.custo_terreno"
                placeholder=" "
                labelStyles="font-size: 1.4rem"
                label="Terreno"
                font-size="15px"
                class="w-full"
                :type="InputType.TEXT"
                :mask="MaskType.DINHEIRO"
                :col-span="1"/>
            <InputField
                name="custo_obra"
                :is-required="true"
                v-model:value="calculadora.custo_obra"
                placeholder=" "
                labelStyles="font-size: 1.4rem"
                label="Obra"
                font-size="15px"
                class="w-full"
                :type="InputType.TEXT"
                :mask="MaskType.DINHEIRO"
                :col-span="1"/>
            <InputField
                name="custo_outorga"
                :is-required="true"
                v-model:value="calculadora.custo_outorga"
                placeholder=" "
                labelStyles="font-size: 1.4rem"
                label="Outorga"
                font-size="15px"
                class="w-full"
                :type="InputType.TEXT"
                :mask="MaskType.DINHEIRO"
                :col-span="1"/>
            <InputField
                name="Outros"
                :is-required="true"
                v-model:value="calculadora.custo_outros"
                placeholder=" "
                labelStyles="font-size: 1.4rem"
                label="Outros"
                font-size="15px"
                class="w-full"
                :type="InputType.TEXT"
                :mask="MaskType.DINHEIRO"
                :col-span="1"/>
        </div>

        <div class="flex">
            <p class="text-[1.4rem]">Custo Total: </p>
            <p v-if="calculadora.custo_terreno && calculadora.custo_obra && calculadora.custo_outorga && calculadora.custo_outros"
            class="ml-[1rem] text-[1.4rem] font-bold"> {{ Money(
                Number(calculadora.custo_terreno) + Number(calculadora.custo_obra) + Number(calculadora.custo_outorga) + Number(calculadora.custo_outros)
            )}}</p>
            <p v-else class="ml-[1rem] text-[1.4rem] font-bold">{{Money(0)}}</p>
        </div>
    </div>

</template>

<style scoped>

</style>