<script lang="ts">
import { Money, Percent } from '@/utils/formats'
import infoIcon from '@/assets/info-icon.svg';
import { getOffset } from '@/services/Browser';

export default {
  name: "CardRatingIncorporadora",
  props: {
    calculadora: { type: Object, default: undefined },
  },
  data() {
    return {
      Money,
      Percent,
      getOffset,

      infoIcon,
      windowTop: 0,
      isFixed: false,
    };
  },
  methods: {
    fixedOnTop() {
      let element = this.$refs.ratingIncorporadora as HTMLElement;
      if (!element) return;
      
      const top = this.getOffset(element).top;
      this.windowTop = window.scrollY + 70;

      if (this.windowTop > top) {
        this.isFixed = true;
      } else {
        this.isFixed = false;
      }

      window.addEventListener('scroll', this.fixedOnTop);
    },
  },
  mounted() {
    this.fixedOnTop();
  },

}
</script>

<template>
    <div class="lg:sticky lg:h-[79px] lg:top-[7%] lg:bg-white lg:z-10">
        <div class="flex flex-col gap-[3rem] lg:flex-row lg:justify-between lg:items-center">
            <div class="flex flex-col lg:grid grid-cols-3 gap-[3rem] w-full lg:gap-[2rem] xl:gap-[5rem] 2xl:gap-[10rem] lg:items-center">
                <h2 class="col-span-1 font-medium text-[3rem] lg:text-[3.6rem]">O Incorporador</h2>
                <h2 class="col-span-1 font-medium text-[3rem] lg:text-[3.6rem] lg:truncate lg:max-w-[25rem] xl:max-w-[30rem] 2xl:max-w-[40rem]" :title="calculadora?.nome_incorporadora">{{ calculadora?.nome_incorporadora }}</h2>
                <div class="col-span-1 flex flex-col gap-[3rem] lg:flex-row lg:items-center lg:justify-between">
                    <div class="flex items-center gap-[2rem]">
                        <p class="font-medium text-[1.8rem] grey-label">Rating</p>
                        <p class="text-black text-[2.8rem] lg:text-[3.8rem]">{{ calculadora?.conceito_incorporadora }}</p>
                    </div>
                    <div class="rounded-full light-blue-bg h-[4rem] flex justify-center items-center w-[14rem]">
                        <a target="_blank" class="text-[1.8rem] text-slate-800" :href="calculadora?.site_incorporadora">Conhecer +</a>
                    </div>
                </div>
            </div>
            
        </div>
        <div class="border-[.1rem] border-solid border-[#001a42] my-[2rem]" :class="isFixed ? 'lg:mt-[1rem] lg:my-0' : ''"></div>
    </div>
    <div id="analise-financeira" :class="isFixed ? 'lg:mt-[12.8rem]' : 'lg:mt-[5rem]'">
        <section class="flex flex-col gap-[3rem]">
                <div class="flex justify-between">
                    <h3 class="font-medium text-[2.5rem] dark-blue lg:text-[3rem]">Balanço Financeiro</h3>
                </div>

                <div class="rounded-2xl flex flex-col lg:flex-row text-[1.5rem]">
                <div class="flex flex-col gap-[1rem] lg:gap-[.5rem] lg:flex-row lg:flex-wrap">
                    <div class="light-grey-bg rounded-2xl p-[1.5rem_2rem] lg:text-[1.8rem] lg:w-[49.7%]">
                        <div class="flex justify-between">
                            <p class="font-bold text-[1.8rem] lg:text-[2.2rem]">Ativos</p>
                        </div>
                        <div class="flex justify-between lg:mb-0">
                            <p>Caixa e Disponibilidades</p>
                            <p>{{Money(calculadora?.balanco.caixa_e_aplicacoes_financeiras)}}</p>
                        </div>
                        <div class="flex justify-between lg:mb-0">
                            <p>Circulante</p>
                            <p>{{Money(calculadora?.balanco.ativo_circulante)}}</p>
                        </div>
                        <div class="flex justify-between lg:mb-0">
                            <p>Estoque (imóveis a comercializar)</p>
                            <p>{{Money(calculadora?.balanco.estoques)}}</p>
                        </div>
                        <div class="flex justify-between lg:mb-0">
                            <p>Não Circulante</p>
                            <p>{{Money(calculadora?.balanco.ativo_nao_circulante)}}</p>
                        </div>
                        <div class="flex justify-between items-center border-black border-t  lg:text-[1.8rem]">
                            <p class="flex gap-[.5rem]">
                               <span class="font-bold">Total</span>
                               <div class="relative hover-wrapper">
                                    <img :src="infoIcon" class="w-5 h-5"/>
                                    <div class="hover-info indicadores w-[28rem]">
                                        Ativo Circulante + Ativo Não Circulante
                                    </div>
                                </div>
                            </p>                                  
                            <p class="font-bold">
                                {{ Money(
                                    calculadora?.balanco.ativo_circulante + calculadora?.balanco.ativo_nao_circulante
                                )}}
                            </p>
                        </div>
                    </div>

                    <div class="light-grey-bg rounded-2xl p-[1.5rem_2rem] lg:text-[1.8rem] lg:w-[49.7%]">
                        <div class="flex justify-between">
                            <p class="font-bold text-[1.8rem] lg:text-[2.2rem]">Passivos</p>
                        </div>
                        <div class="flex justify-between lg:mb-0">
                            <p>Circulante</p>
                            <p>{{Money(calculadora?.balanco.passivo_circulante)}}</p>
                        </div>
                        <div class="flex justify-between lg:mb-0">
                            <p>Empréstimos e Financiamentos</p>
                            <p>{{Money(calculadora?.balanco.financiamentos)}}</p>
                        </div>
                        <div class="flex justify-between lg:mb-0">
                            <p>Não Circulante</p>
                            <p>{{Money(calculadora?.balanco.passivo_nao_circulante)}}</p>
                        </div>
                        <div class="flex justify-between lg:mb-0">
                            <p>Patrimônio Líquido</p>
                            <p>{{Money(calculadora?.balanco.patrimonio_liquido)}}</p>
                        </div>
                        <div class="flex justify-between items-center border-black border-t  lg:text-[1.8rem]">
                            <p class="flex gap-[.5rem]">
                               <span class="font-bold">Total</span>
                               <div class="relative hover-wrapper">
                                    <img :src="infoIcon" class="w-5 h-5"/>
                                    <div class="hover-info indicadores w-[32rem]">
                                        Passivo Circulante + Passivo Não Circulante + Patrimônio Líquido
                                    </div>
                                </div>
                            </p>                                
                            <p class="font-bold">
                                {{ Money(
                                    calculadora?.balanco.passivo_circulante + calculadora?.balanco.passivo_nao_circulante + calculadora?.balanco.patrimonio_liquido
                                )}}
                            </p>
                        </div>
                    </div>

                    <div class="light-grey-bg rounded-2xl flex items-center justify-between p-[1.5rem_2rem] order-5 lg:w-[49.7%] lg:order-7">
                        <p class="font-bold text-[1.8rem] lg:text-[2.2rem]">Faturamento Bruto</p>
                        <p class="font-bold text-[1.5rem] lg:text-[1.8rem]">{{Money(calculadora?.balanco.receita_bruta)}}</p>
                    </div>
                    <div class="light-grey-bg rounded-2xl flex items-center justify-between p-[1.5rem_2rem] order-6 lg:w-[49.7%] lg:order-last">
                        <p class="font-bold text-[1.8rem] lg:text-[2.2rem]">Lucro Líquido</p>
                        <p class="font-bold text-[1.5rem] lg:text-[1.8rem]">{{Money(calculadora?.balanco.lucro_liquido)}}</p>
                    </div> 
                </div>
            
            </div>
        </section>
    </div>
    <div id="indicadores" class="mt-[5rem]">
        <section class="flex flex-col gap-[3rem]">
                <div class="flex">
                    <h3 class="font-medium text-[2.5rem] dark-blue lg:text-[3rem]">Indicadores Financeiros</h3>
                </div>
                <div class="rounded-2xl flex flex-col lg:flex-row text-[1.5rem]">
                    <div class="flex flex-col gap-[1rem] lg:gap-[.5rem] lg:flex-row lg:flex-wrap">
                        <div class="light-grey-bg rounded-2xl p-[1.5rem_2rem] lg:text-[1.8rem] lg:w-[49.7%]">
                            <div class="flex justify-between">
                                <p class="font-bold text-[1.8rem] lg:text-[2.2rem]">Liquidez</p>
                            </div>
                            <div class="flex justify-between lg:mb-0">
                                <p class="flex gap-[.5rem]">
                                    Imediata
                                    <div class="relative hover-wrapper">
                                        <img :src="infoIcon" class="w-5 h-5"/>
                                        <div class="hover-info indicadores w-[28rem]">
                                            Caixa e Disponibilidades / Passivo Circulante
                                        </div>
                                    </div>
                                </p>
                                <p class="flex gap-[2rem]">
                                    <span>{{Percent(calculadora?.indicadores.imediata.valor)}}</span>
                                    <div class="flex justify-between w-[4rem]">
                                        <span class="font-bold">{{ calculadora?.indicadores.imediata.conceito}}</span>
                                        <div class="relative hover-wrapper">
                                            <img :src="infoIcon" class="w-5 h-5"/>
                                            <div class="hover-info indicadores right w-[18rem]">
                                                C- &lt; {{ Percent(calculadora?.indicadores.imediata.menor_nota)}} e A+ &gt; {{ Percent(calculadora?.indicadores.imediata.maior_nota) }}
                                            </div>
                                        </div>
                                    </div>
                                </p>
                            </div>
                            <div class="flex justify-between lg:mb-0">
                                <p class="flex gap-[.5rem]">
                                    Seca
                                <div class="relative hover-wrapper">
                                    <img :src="infoIcon" class="w-5 h-5"/>
                                    <div class="hover-info indicadores w-[24rem]">
                                        Ativo Circulante / Passivo Circulante
                                    </div>
                                </div>
                                </p>
                                <p class="flex gap-[2rem]">
                                    <span>{{Percent(calculadora?.indicadores.seca.valor)}}</span>
                                    <div class="flex justify-between w-[4rem]">
                                        <span class="font-bold">{{ calculadora?.indicadores.seca.conceito}}</span>
                                        <div class="relative hover-wrapper">
                                            <img :src="infoIcon" class="w-5 h-5"/>
                                            <div class="hover-info indicadores right w-[18rem]">
                                                C- &lt; {{ Percent(calculadora?.indicadores.seca.menor_nota)}} e A+ &gt; {{ Percent(calculadora?.indicadores.seca.maior_nota) }}
                                            </div>
                                        </div>
                                    </div>
                                </p>
                            </div>
                            <div class="flex justify-between lg:mb-0">
                                <p class="flex gap-[.5rem]">
                                    Corrente
                                <div class="relative hover-wrapper">
                                    <img :src="infoIcon" class="w-5 h-5"/>
                                    <div class="hover-info indicadores w-[30rem]">
                                        (Ativo Circulante - Estoques) / Passivo Circulante
                                    </div>
                                </div>
                                </p>
                                <p class="flex gap-[2rem]">
                                    <span>{{Percent(calculadora?.indicadores.corrente.valor)}}</span>
                                    <div class="flex justify-between w-[4rem]">
                                        <span class="font-bold">{{ calculadora?.indicadores.corrente.conceito}}</span>
                                        <div class="relative hover-wrapper">
                                            <img :src="infoIcon" class="w-5 h-5"/>
                                            <div class="hover-info indicadores right w-[18rem]">
                                                C- &lt; {{ Percent(calculadora?.indicadores.corrente.menor_nota)}} e A+ &gt; {{ Percent(calculadora?.indicadores.corrente.maior_nota) }}
                                            </div>
                                        </div>
                                    </div>
                                </p>
                            </div>
                        </div>

                        <div class="light-grey-bg rounded-2xl p-[1.5rem_2rem] lg:text-[1.8rem] lg:w-[49.7%]">
                            <div class="flex justify-between">
                                <p class="font-bold text-[1.8rem] lg:text-[2.2rem]">Endividamento</p>
                            </div>
                            <div class="flex justify-between lg:mb-0">
                                <p class="flex gap-[.5rem]">
                                    Curto Prazo
                                <div class="relative hover-wrapper">
                                    <img :src="infoIcon" class="w-5 h-5"/>
                                    <div class="hover-info indicadores w-[38rem]">
                                        Passivo Circulante / (Passivo Circulante + Passivo Não Circulante)
                                    </div>
                                </div>
                                </p>
                                <p class="flex gap-[2rem]">
                                    <span>{{Percent(calculadora?.indicadores.curto_prazo.valor)}}</span>
                                    <div class="flex justify-between w-[4rem]">
                                        <span class="font-bold">{{ calculadora?.indicadores.curto_prazo.conceito}}</span>
                                        <div class="relative hover-wrapper">
                                            <img :src="infoIcon" class="w-5 h-5"/>
                                            <div class="hover-info indicadores right w-[18rem]">
                                                C- &gt; {{ Percent(calculadora?.indicadores.curto_prazo.menor_nota)}} e A+ &lt; {{ Percent(calculadora?.indicadores.curto_prazo.maior_nota) }}
                                            </div>
                                        </div>
                                    </div>
                                </p>
                            </div>
                            <div class="flex justify-between lg:mb-0">
                                <p class="flex gap-[.5rem]">
                                    Longo Prazo
                                <div class="relative hover-wrapper">
                                    <img :src="infoIcon" class="w-5 h-5"/>
                                    <div class="hover-info indicadores w-[24rem]">
                                        Passivo Não Circulante / Ativo Total
                                    </div>
                                </div>
                                </p>
                                <p class="flex gap-[2rem]">
                                    <span>{{Percent(calculadora?.indicadores.longo_prazo.valor)}}</span>
                                    <div class="flex justify-between w-[4rem]">
                                        <span class="font-bold">{{ calculadora?.indicadores.longo_prazo.conceito}}</span>
                                        <div class="relative hover-wrapper">
                                            <img :src="infoIcon" class="w-5 h-5"/>
                                            <div class="hover-info indicadores right w-[18rem]">
                                                C- &gt; {{ Percent(calculadora?.indicadores.longo_prazo.menor_nota)}} e A+ &lt; {{ Percent(calculadora?.indicadores.longo_prazo.maior_nota) }}
                                            </div>
                                        </div>
                                    </div>
                                </p>
                            </div>
                            <div class="flex justify-between lg:mb-0">
                                <p class="flex gap-[.5rem]">
                                    Geral
                                <div class="relative hover-wrapper">
                                    <img :src="infoIcon" class="w-5 h-5"/>
                                    <div class="hover-info indicadores w-[40rem]">
                                        (Passivo Circulante + Passivo Não Circulante) / Patrimônio Líquido
                                    </div>
                                </div>
                                </p>
                                <p class="flex gap-[2rem]">
                                    <span>{{Percent(calculadora?.indicadores.geral.valor)}}</span>
                                    <div class="flex justify-between w-[4rem]">
                                        <span class="font-bold">{{ calculadora?.indicadores.geral.conceito}}</span>
                                        <div class="relative hover-wrapper">
                                            <img :src="infoIcon" class="w-5 h-5"/>
                                            <div class="hover-info indicadores right w-[18rem]">
                                                C- &gt; {{ Percent(calculadora?.indicadores.geral.menor_nota)}} e A+ &lt; {{ Percent(calculadora?.indicadores.geral.maior_nota) }}
                                            </div>
                                        </div>
                                    </div>
                                </p>
                            </div>
                        </div>

                        <div class="light-grey-bg rounded-2xl p-[1.5rem_2rem] lg:text-[1.8rem] lg:w-[49.7%]">
                            <div class="flex justify-between">
                                <p class="font-bold text-[1.8rem] lg:text-[2.2rem]">Rentabilidade</p>
                            </div>
                            <div class="flex justify-between lg:mb-0">
                                <p class="flex gap-[.5rem]">
                                    ROE
                                <div class="relative hover-wrapper">
                                    <img :src="infoIcon" class="w-5 h-5"/>
                                    <div class="hover-info indicadores w-[22rem]">
                                        Lucro Líquido / Patrimônio Líquido
                                    </div>
                                </div>
                                </p>
                                <p class="flex gap-[2rem]">
                                    <span>{{Percent(calculadora?.indicadores.roe.valor)}}</span>
                                    <div class="flex justify-between w-[4rem]">
                                        <span class="font-bold">{{ calculadora?.indicadores.roe.conceito}}</span>
                                        <div class="relative hover-wrapper">
                                            <img :src="infoIcon" class="w-5 h-5"/>
                                            <div class="hover-info indicadores right w-[18rem]">
                                                C- &lt; {{ Percent(calculadora?.indicadores.roe.menor_nota)}} e A+ &gt; {{ Percent(calculadora?.indicadores.roe.maior_nota) }}
                                            </div>
                                        </div>
                                    </div>
                                </p>
                            </div>
                            <div class="flex justify-between lg:mb-0">
                                <p class="flex gap-[.5rem]">
                                    Margem Líquida
                                <div class="relative hover-wrapper">
                                    <img :src="infoIcon" class="w-5 h-5"/>
                                    <div class="hover-info indicadores w-[22rem]">
                                        Lucro Líquido / Faturamento Bruto
                                    </div>
                                </div>
                                </p>
                                <p class="flex gap-[2rem]">
                                    <span>{{Percent(calculadora?.indicadores.margem_liquida.valor)}}</span>
                                    <div class="flex justify-between w-[4rem]">
                                        <span class="font-bold">{{ calculadora?.indicadores.margem_liquida.conceito}}</span>
                                        <div class="relative hover-wrapper">
                                            <img :src="infoIcon" class="w-5 h-5"/>
                                            <div class="hover-info indicadores right w-[18rem]">
                                                C- &lt; {{ Percent(calculadora?.indicadores.margem_liquida.menor_nota)}} e A+ &gt; {{ Percent(calculadora?.indicadores.margem_liquida.maior_nota) }}
                                            </div>
                                        </div>
                                    </div>
                                </p>
                            </div>
                            <div class="flex justify-between lg:mb-0">
                                <p class="flex gap-[.5rem]">
                                    ROIC
                                <div class="relative hover-wrapper">
                                    <img :src="infoIcon" class="w-5 h-5"/>
                                    <div class="hover-info indicadores left w-[40rem]">
                                        Lucro Líquido / (Patrimônio Líquido - Empréstimos e Financiamentos)
                                    </div>
                                </div>
                                </p>
                                <p class="flex gap-[2rem]">
                                    <span>{{Percent(calculadora?.indicadores.roic.valor)}}</span>
                                    <div class="flex justify-between w-[4rem]">
                                        <span class="font-bold">{{ calculadora?.indicadores.roic.conceito}}</span>
                                        <div class="relative hover-wrapper">
                                            <img :src="infoIcon" class="w-5 h-5"/>
                                            <div class="hover-info indicadores right w-[18rem]">
                                                C- &lt; {{ Percent(calculadora?.indicadores.roic.menor_nota)}} e A+ &gt; {{ Percent(calculadora?.indicadores.roic.maior_nota) }}
                                            </div>
                                        </div>
                                    </div>
                                </p>
                            </div>
                        </div>

                        <div class="light-grey-bg rounded-2xl p-[1.5rem_2rem] lg:text-[1.8rem] lg:w-[49.7%]">
                            <div class="flex justify-between">
                                <p class="font-bold text-[1.8rem] lg:text-[2.2rem]">Histórico</p>
                            </div>
                            <div class="flex justify-between lg:mb-0">
                                <p class="flex gap-[.5rem]">
                                    Tempo de Fundação
                                </p>
                                <p class="flex gap-[2rem]">
                                    <span>{{calculadora?.indicadores.tempo_de_fundacao.valor}} anos</span>
                                    <div class="flex justify-between w-[4rem]">
                                        <span class="font-bold">{{ calculadora?.indicadores.tempo_de_fundacao.conceito}}</span>
                                        <div class="relative hover-wrapper">
                                            <img :src="infoIcon" class="w-5 h-5"/>
                                            <div class="hover-info indicadores right w-[14rem]">
                                                C- &lt; {{ calculadora?.indicadores.tempo_de_fundacao.menor_nota}} e A+ &gt; {{ calculadora?.indicadores.tempo_de_fundacao.maior_nota }} anos
                                            </div>
                                        </div>
                                    </div>
                                </p>
                            </div>
                            <div class="flex justify-between lg:mb-0">
                                <p class="flex gap-[.5rem]">
                                    Obras Entregues
                                <div class="relative hover-wrapper">
                                    <img :src="infoIcon" class="w-5 h-5"/>
                                    <div class="hover-info indicadores w-[28rem]">
                                        Quantidade de empreendimentos concluídos
                                    </div>
                                </div>
                                </p>
                                <p class="flex gap-[2rem]">
                                    <span>{{calculadora?.indicadores.obras_entregues.valor}} obras</span>
                                    <div class="flex justify-between w-[4rem]">
                                        <span class="font-bold">{{ calculadora?.indicadores.obras_entregues.conceito}}</span>
                                        <div class="relative hover-wrapper">
                                            <img :src="infoIcon" class="w-5 h-5"/>
                                            <div class="hover-info indicadores right w-[14rem]">
                                                C- &lt; {{ calculadora?.indicadores.obras_entregues.menor_nota}} e A+ &gt; {{ calculadora?.indicadores.obras_entregues.maior_nota }} obras
                                            </div>
                                        </div>
                                    </div>
                                </p>
                            </div>
                        </div>
                        <div class="p-[1.5rem_2rem] w-full">
                        </div>
                    </div>
                </div>
        </section>
    </div>
</template>
