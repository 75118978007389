import { IFormInstance, ModelForm } from "../services/Form";

export interface Investimento {
    captacao_id: number,
    investimento_id: number,
    nome_projeto: string,
    cidade_projeto: string,
    nome_incorporadora: string,
    capa: string,
    incorporadora_logo: string,
    captacao_nome: string,
    tir: number,
    total_investido: number,
    retorno: number,
    parcelas_pagas: number,
    numero_parcelas: number,
    prazo: string;
    status: string,
    status_reserva: string,
    pix: number,
    tipo: string,
    investimento_data: string,

    url_investimento?: string,
    url_projeto?: string;
    tipo_pagamento?: string,
    carencia?: string,
    vencimento?: string,
    nome_captacao?: string;
}


export interface CaptacaoAberta extends Investimento {
    rentabilidade_minima : number;
    porcentagem: number;
    datahora_fim: string;
    slug_incorporadora: string;
    projeto_slug: string;
    esgotada: boolean;
    exportar_prospecto_url: string;
    parte: string;
}

export interface DadosContadorHome {
    numero_moradias: number;
    numero_cidades: number;
    numero_projetos: number;
    total_captado: number;
}

export interface DadosProjeto {

    projeto_slug: string;
    id: number;
    capa: string;
    nome_projeto: string;
    cidade_projeto: string;
    apresentacao: string;
    url_mapa: string;
    site: string;
    video: string;
    video_embed: string;
    endereco: string;
    latitude: number;
    longitude: number;
    percentual_vendas: number;
    percentual_obras: number;
    visualizacao_privada_projeto: boolean;

    logo_incorporadora: string;
    sede_incorporadora: string;

    imagens: Array<DadosImagens>;
    vendas: Array<any>;
    obra: Array<any>;
    
    captacoes: Array<DadosCaptacao>;
    exportar_pdf_rating_url: string;
    numero_unidades: number;

    viabilidade: DadosViabilidade;

    fluxo_de_caixa: DadosFluxoDeCaixa;

    balanco_patrimonial_incorporadora: string;
}

export interface DadosCaptacao { 
    nome_captacao: string;
    captacao_id: number;
    tir: string;
    rentabilidade_minima: string;
    tipo_pagamento: string;
    carencia: string;
    vencimento: string;
    porcentagem: number;
    status: string;
    datahora_fim: string;
    numero_investidores: number;
    slug_captacao: string;

    url_investimento: string;
    url_projeto: string;

    nome_incorporadora: string;
    slug_incorporadora: string;
    nome_projeto: string;
    projeto_slug: string;
    incorporadora_logo: string;
    exportar_prospecto_url: string;
    esgotada: boolean;

    parcelas_carencia: number
    tipo_carencia: string
}

export interface DadosViabilidade {
    vgv_bruto: number;
    vgv_liquido_de_permuta: number;
    vgv_liquido: number;
    total_vendido: number;
    roi_projetado: number;
    roi_exposicao_de_caixa: number;
    tir_projetado: number;
    margem_liquida: number;
}

export interface DadosImagens {
    imagem: string;
}

export interface DadosFluxoDeCaixa {
    fluxo_de_caixa_projetado: Array<any>;
    grafico_fluxo_de_caixa_projetado: Array<any>;
}



export interface AvaliacaoInvestimento {
    investimento: number;
    nota: number;
}


export interface IInvestimentoValores extends IFormInstance {
    saldo : boolean;
    quantidade_cotas: number;
}

export class InvestimentoValoresForm extends ModelForm {
    instance!: IInvestimentoValores;
}

export interface IInvestimentoTermos extends IFormInstance {
    termos: boolean
}

export class InvestimentoTermosForm extends ModelForm {
    instance!: IInvestimentoTermos;
}

export interface IInvestimentoAutenticacao extends IFormInstance {
    de_acordo: boolean;
    sign_method: string;
}

export class InvestimentoAutenticacaoForm extends ModelForm {
    instance!:IInvestimentoAutenticacao;
}

export interface IInvestimentoAssinatura extends IFormInstance {
    de_acordo: boolean;
}

export class InvestimentoAssinaturaForm extends ModelForm {
    instance!: IInvestimentoAssinatura;
}

export interface Carteira {
    saldo: number;
    saldoUtilizado: number;
}

export interface Termo {
    titulo: string;
    conteudo: string;
    id: string;
}

export interface Termos {
    riscos: Termo,
    aceite: Termo,
    pep: Termo
}

export interface Pix {
    emv: string;
    qrcode: string;
    vencimento?: string;
}