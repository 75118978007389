<script lang="ts">
import { inject } from "vue";
import { SplideSlide } from '@splidejs/vue-splide';
import uLogo from '@/assets/u-logo-home.svg';
import urbeLogo from '@/assets/urbe_me_logo_home.svg';
import ospaArqLogo from '@/assets/ospa_arq.svg';
import ospaLogo from '@/assets/ospa.svg';
import urbemeLogo from '@/assets/urbeme_blue.svg';
import placeLogo from '@/assets/place.svg';
import urbemeWhiteLogo from '@/assets/urbeme_white.svg';
import byOspaLogo from '@/assets/byospa.svg';
import bancoCentralImg from '@/assets/bancocentral.png';
import socinalImg from '@/assets/socinal.png';
import institutoLogo from '@/assets/inst_logo.svg';
import arquiteturaLogo from '@/assets/arquitetura.svg';
import CardInfo from '@/Components/CardInfo.vue';
import CardInfoContainer from '@/Components/CardInfoContainer.vue';
import CardCaptacaoContainer from "@/Components/CardCaptacaoContainer.vue";
import CardCaptacaoAberta from "@/Components/CardCaptacaoAberta.vue";
import Counter from "@/Components/Counter.vue";
import GeneralGateway from '@/infra/gateway/generalGateway';
import { CaptacaoAberta, DadosContadorHome } from "@/Types/Investimento";
import modalService from '@/services/ModalService';
import {getOffset, clearParams,getParams} from '@/services/Browser';
import InvestidorGateway from "@/infra/gateway/investidorGateway";
import {DadosAcessoForm} from '@/Types/Investidor';
import { isMobile } from '@/services/Mobile';
import { Processing } from '@/Types/General'
import { ModelForm } from "@/services/Form";
import {ICardInfo} from '@/Types/CardInfo';



export default {
    name: "Home",
    components: {
        CardInfo,
        CardInfoContainer,
        CardCaptacaoContainer,
        CardCaptacaoAberta,
        SplideSlide,
        Counter,
    },
    setup() {
        return {
            investidorGateway: inject<InvestidorGateway>('investidorGateway')!,
            generalGateway: inject<GeneralGateway>('generalGateway')!,
        }
    },
    data() {

        const dadosAcessoForm = new DadosAcessoForm(
            (any:any)=>any,
            //@ts-ignore
            (this.investidorGateway as InvestidorGateway).postRedefSenha
        )
        return {
            processing: new Processing(),
            isView: false,

            uLogo,
            urbeLogo,
            ospaLogo,
            ospaArqLogo,
            urbemeLogo,
            placeLogo,
            institutoLogo,
            arquiteturaLogo,
            urbemeWhiteLogo,
            byOspaLogo,
            bancoCentralImg,
            socinalImg,
            isMobile,



            captacoesAbertas: undefined as CaptacaoAberta[] | undefined,
            dadosContador: undefined as DadosContadorHome | undefined,
            dadosAcessoForm,
            
            infos: [
                {
                   title: "1",
                   subTitle :  "Originação <br>e Governança",
                   info: "Avaliamos informações detalhadas sobre a saúde financeira e a viabilidade dos empreendimentos apresentados por incorporadoras parceiras de todo o Brasil. Nosso sistema traz transparência e governança para as incorporadoras, facilitando a introdução de novos empreendimentos no mercado de capitais.",
                   button:{
                    name: 'Quero fazer uma avaliação',
                    link: '/calculadora/'}
                }, 
                {
                    title: "2",
                    subTitle :  "Marketplace",
                    info: "Conectamos empreendimentos a investidores individuais, profissionais e institucionais por meio de um painel exclusivo de ofertas. Nossa plataforma oferece uma ampla gama de opções de investimento com estruturas variadas, diferentes períodos de carência e prazos personalizados para atender às necessidades de cada investidor. Pelo painel, é possível acessar diversas operações com variações em geolocalização, garantias e prazos.",
                    button:{
                        name: 'Investir',
                        action: this.investButtonAction}
                },
                {
                    title: "3",
                    subTitle :  "Acompanhamento <br>e Gestão",
                    info: "Disponibilizamos um painel centralizado onde investidores podem acompanhar todas as informações sobre os empreendimentos e operações em que investiram. Monitore o progresso das obras, vendas, adimplência e mantenha-se atualizado com as comunicações das incorporadoras responsáveis, garantindo uma gestão eficiente dos seus investimentos.",
                    button:{
                        name: 'Acompanhar Investimentos',
                        link: '/dashboard/investidor'}
                }
            ] as Array<ICardInfo>,
            
            getOffset,
            getParams,
            clearParams,

        };
    },
    methods : {
        async getCaptacoesAbertas (){
            const captacoes = await this.generalGateway.getCaptacoesAbertas();
            this.captacoesAbertas = captacoes;
        },
        async getDadosContadorHome() {
            const dadosContador = await this.generalGateway.getDadosContadorHome();
            this.dadosContador = dadosContador;
            this.checkContador();
        },
        investButtonAction() { 
            if(this.captacoesAbertas && this.captacoesAbertas.length > 0){
                const containerCaptacaoAberta = document.getElementById("investiment");
                if(!containerCaptacaoAberta) return;
                const top = this.getOffset(containerCaptacaoAberta).top;
                window.scroll({ top: top, behavior: 'smooth'})
                return;
            }
            modalService.mensagem("Em breve, nova captação.");
        },
        checkContador() {
            const contador = document.getElementById('contador');
            if(!contador) return;
            this.isView = true ? window.scrollY >= contador?.getBoundingClientRect().top : false;
 
            window.addEventListener('scroll', () => {
                if(window.scrollY >= contador?.getBoundingClientRect().top){
                    this.isView = true;
                }      
            });
            
        },
        checkRedefSenha(){
            const uu = getParams('uu');
            if(uu) {
                clearParams('uu');
                modalService.redefinirSenhaModal((e:any,o:any) => {
                this.dadosAcessoForm.loadInstance(o);
                this.dadosAcessoForm.instance.uuid = uu;
                this.handleSubmit(e,this.dadosAcessoForm)
                }, false) 
            }  
        },

        canSubmit() {
            return !this.processing.isBusyOrSetBusy();
        },

        resetSubmit(success: boolean) {
            this.processing.setValue(false);
            if (!success) return;
        },

        async handleSubmit(evento: SubmitEvent, form: ModelForm) {
            if(!this.canSubmit()) return;
            form.save(evento, this.resetSubmit, false, false)
        },

    },
    async mounted() {
        this.checkRedefSenha();
        this.getCaptacoesAbertas();
        this.getDadosContadorHome();
        if (!isMobile()) {
            document.getElementById('background-video')?.setAttribute('autoplay', '')
        }
    },
}

</script>

<template>
    <section id="intro" class="w-full">
        <div class="platform">
            <div class="video-wrapper">
                <video id="background-video" ref="video" class="landing-background object-cover"
                    poster="https://storage.googleapis.com/plataforma_django_prod/public/video/background_video_placeholder.png"
                    loop muted>
                    <source
                        src="https://storage.googleapis.com/plataforma_django_prod/public/video/background_video_high.mp4"
                        type="video/mp4">
                </video>
            </div>

            <div class="ctn home-container hero pt-[15rem] pb-[6rem] sm:py-[15rem]">
            <div class="flex items-end gap-[.24rem] mt-[3rem] mb-[5rem] sm:mt-[3rem]">
                <img id="logo-home-u" :src="uLogo" style="max-height: 3.06rem;" class="z-[20] md:z-[1000000]"/>
                <img id="logo-home-body" :src="urbeLogo" class="h-[4rem]" />
            </div>
            <div class="flex flex-col gap-[10rem] items-normal md:flex-row md:items-end md:justify-between">
                <h1 data-aos="fade-right" class="text-[3rem] sm:text-[4rem] mb-[0rem] leading-[105%] font-normal">Simplificamos<br />o desenvolvimento urbano<br />conectando investidores<br />a projetos imobiliários!</h1>
                <button 
                @click="investButtonAction"
                class="text-white font-normal dark-blue-bg text-[1.8rem] p-[1rem_7rem] rounded-[5rem] mr-[1.5rem]">Investir</button>
            </div>
        </div>
        </div>
    </section>


    <section id="how-to" class="w-full mb-[10rem]">
        <CardInfoContainer 
            title="O que fazemos"
            arrow-id="arrow-vantagens"
            :breakpoint="1280"
            custom-arrow-class="hide"
            >
            <template v-for="info in infos">
                <SplideSlide>
                    <CardInfo>
                        <div class="w-full h-full p-[2.8rem] flex flex-col justify-between">
                            <div class="flex flex-col justify-start gap-[2rem] w-full">
                                <h3 v-if="info.subTitle" class="text-[2.8rem] font-bold" v-html="info.subTitle"></h3>
                                <span class="grey-label leading-[105%] text-left text-[1.6rem] font-normal">
                                    {{ info.info }}
                                </span>
                            </div>
                            <div v-if="info.button" class="button-wrapper w-full">
                                <a v-if="info.button.action" @click="info.button.action" class="w-full flex justify-center dark-blue-bg text-white font-normal p-4 rounded-[5rem] pointer" type="button" >{{ info.button.name }}</a>
                                
                                <a v-else :href="info.button.link" class="w-full flex justify-center dark-blue-bg text-white font-normal p-4 rounded-[5rem]" type="button">{{ info.button.name }}</a>
                            </div>
                        </div>
                    </CardInfo>
                </SplideSlide>
            </template>
        </CardInfoContainer>
    </section>

    <section id="contador">
        <template v-if="isView">
            <div class="ctn home-container mb-[8rem] py-[5rem] text-center zoom-in">
                <p class="font-medium text-[2.5rem] min-[530px]:text-[3rem]">Já ajudamos a criar <br class="md:hidden"><Counter classes="text-[10rem]" :target="dadosContador?.numero_moradias"/> moradias<br class="min-[428px]:hidden">
                    em <Counter classes="text-[10rem]" :target="dadosContador?.numero_cidades" /> cidades,<br>
                    para <Counter classes="text-[10rem]" :target="dadosContador?.numero_projetos" /> empreendimentos imobiliários.</p>
            </div>
        </template>
    </section>

    <section v-if="captacoesAbertas && captacoesAbertas.length > 0" id="investiment">
        <div class="ctn home-container mb-[5rem]">
            <div class="investiment-header">
                <h2 class="font-home-title" data-aos="fade-right">Invista</h2>
                <div v-if="captacoesAbertas.length > 1" class="arrow-wrapper investiment-arrows flex 2xl:hidden">
                    <i class="fas fa-angle-left clr-gry arrow arrow-left"></i>
                    <i class="fas fa-angle-right clr-gry arrow arrow-right"></i>
                </div>
            </div>
        </div>
        <CardCaptacaoContainer
            tipo="home"
            v-for="captacao in captacoesAbertas"
            :bgImage="captacao.capa">
            <CardCaptacaoAberta :captacao="captacao" tipo="home" />
        </CardCaptacaoContainer>

    </section>


    <section class="w-full" id="group-bg">
        <div class="ctn home-container px-[3rem]">
            <div class="items-center mx-[1.5rem] xl:grid xl:grid-cols-2 lg:mx-[0rem]">
                <div class="flex flex-col gap-[3rem] lg:relative lg:px-[1.5rem]">
                    <div class="flex gap-[1.5rem]">
                        <p class="text-[2.2rem] font-weigth-[400]">Grupo</p>
                        <a href="https://pt.ospa.com.br/" target="_blank"><img :src="ospaLogo" alt="ospa"></a>
                    </div>
                    <h2 class="font-medium text-[5rem] leading-[105%] mb-[6rem]">We stand for<br>responsive cities</h2>
                </div>
                <div class="flex-[0_0_100%] lg:grid-flow-col lg:grid lg:grid-rows-2 lg:gap-x-[9.3rem] lg:gap-y-[3.3rem]">
                    <div class="leading-[1.9rem] mb-[5rem] lg:mb-[2rem] lg:px-[1.5rem] lg:flex lg:flex-col">
                        <div class="lg:mb-[1.5rem]">
                            <img :src="urbemeLogo" alt="urbe.me" class="pb-[3rem] w-[9.5rem]">
                            <p class="mb-[3rem] grey-label lg:mb-[1.6rem]">Plataforma de<br>Investimento<br>Imobiliário</p>
                        </div>  
                        <button
                            @click="investButtonAction"
                            class="btn-outline-urbe urbe lg:px-[3rem] lg:h-[3.6rem]"
                            type="button">Investir</button>
                    </div>
                    <div class="leading-[1.9rem] mb-[5rem] lg:mb-[2rem] lg:px-[1.5rem] lg:flex lg:flex-col lg:col-start-2 lg:row-start-1">
                        <div class="lg:mb-[1.5rem]">
                            <img :src="placeLogo" alt="place" class="pb-[3rem]">
                            <p class="mb-[3rem] grey-label lg:mb-[1.6rem]">Tecnologia para<br>Desenvolvimento <br>Urbano</p>
                        </div>
                        <a class="btn-outline-urbe place lg:px-[3rem] lg:h-[3.6rem]" type="button" href="https://ospa.place/home"
                            target="_blank">Conhecer</a>
                    </div>
                    <div class="leading-[1.9rem] mb-[5rem] lg:mb-[2rem] lg:px-[1.5rem] lg:flex lg:flex-col">
                        <div class="mt-[2rem] lg:mt-0 lg:mb-[1.5rem]">
                            <div class="flex pb-[3rem] gap-[3rem]">
                                <img :src="ospaArqLogo" class="h-[4rem]" alt="ospa">
                            </div>
                            <p class="mb-[3rem] grey-label lg:mb-[1.6rem]">Projetos de<br>Arquitetura e<br>Urbanismo</p>
                        </div>
                        <a class="btn-outline-urbe ospa lg:px-[3rem] lg:h-[3.6rem]" type="button" href="https://pt.ospa.com.br/"
                            target="_blank">Conhecer</a>
                    </div>
                    <div class="leading-[1.9rem] mb-[5rem] lg:mb-[2rem] lg:px-[1.5rem] lg:flex lg:flex-col  lg:col-start-2 lg:row-start-2">
                        <div class="mt-[2rem] lg:mt-0 lg:mb-[1.5rem]">
                            <img :src="institutoLogo" alt="responsive cities" class="pb-[3rem] h-[7rem] max-w-[31.2rem]"
                                >
                            <p class="mb-[3rem] grey-label lg:mb-[1.6rem]">Arquitetura,<br>Tecnologia e<br>Economia Urbana</p>
                        </div>
                        <a class="btn-outline-urbe instituto lg:px-[3rem] lg:h-[3.6rem]" type="button" href="https://www.responsivecities.com/"
                            target="_blank">Conhecer</a>
                    </div>
                </div>
            </div>
        </div>

    </section>

</template>