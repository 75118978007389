<script lang="ts">
import {  defineComponent, PropType } from 'vue';
import { Processing } from '@/Types/General';
import {Clicksign, ClicksignTest} from '@/services/Clicksign';
import Loading from '@/Components/Loading.vue';
import { authentication } from '@/services/Authentication';


export default defineComponent({
    name: "FormularioInvestimentoAssinatura",
    props : {
        reqKey: {type: String, default: undefined},
        assinaturaData:{type: Object, default: undefined},
        processing: {type: Object as PropType<Processing>, required: true},
    },
    components: {
        Loading
    },
    data() {
        return {
            Clicksign,

            showLoading: true

        }
    },
    methods: {
        setClickSignClass(req_sign_key: any) {
            if(authentication.getUserAccess()['email'] == 'andremezzaliraribeiro@gmail.com.br') {
                return new ClicksignTest(req_sign_key)
            }
            return new Clicksign(req_sign_key)
        },

        mount_clicksign(req_sign_key: any) {
            const id_div = 'clicksign_content'
            var widget;

            if(widget) { widget.unmount(); }

            widget = this.setClickSignClass(req_sign_key);

            widget.endpoint = this.assinaturaData.endpoint
            widget.origin = this.assinaturaData.host
            widget.mount(id_div);
            widget.on('loaded', (ev:any ) => { 
                this.showLoading = false
                let div = document.getElementById(id_div);
                if(div)
                    div.style.display = 'block';
            });
            widget.on('signed', (ev:any) =>  { 
                this.processing.reset();
            });
            widget.on('resized', function(height: any) {
                (document.getElementById(id_div) as HTMLElement).style.height = height+'px';
            });        
        }
    },
})

</script>

<template>
        <div v-if="showLoading" class="py-[13rem] dark-blue flex flex-col items-center gap-4">
            <Loading class="save-loading" :size="2.5" />
           <p class="font-bold font-title"> Gerando CCB</p>
        </div>
        <div id="clicksign_content">
        </div>
</template>


<style scoped>

    input[name='codigo'] {
        text-align: center;
        letter-spacing: 8px;
        border: 0;
        border-bottom: 1px solid darkgray;
        font-size: 35px;
        display: inline-block;
        width: 60%;

    }

    #sign-controls {
        display: none;
    }

    .loader {
        margin: 100px auto;
        font-size: 25px;
        width: 1em;
        height: 1em;
        border-radius: 50%;
        position: relative;
        text-indent: -9999em;
        -webkit-animation: load5 1.1s infinite ease;
        animation: load5 1.1s infinite ease;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
    }

    #erro-ccb {
        display: none;
        position: absolute;
        display: flex;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    #sucessoCodigo>div {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .fa-check {
        color: limegreen;
        margin-right: 20px;
        font-size: 30px;
    }

    #sucessoCodigo {
        display: none;
    }

    #spinner {
        position: relative;
        display: none;
    }

    #sendSmsButton {
        display: none;
    }

    #clicksign_content {
        height: clamp(400px, 50vw, 600px);
        display: none;
    }

    @-webkit-keyframes load5 {

        0%,
        100% {
            box-shadow: 0em -2.6em 0em 0em #007bff, 1.8em -1.8em 0 0em rgba(0, 123, 255, 0.2), 2.5em 0em 0 0em rgba(0, 123, 255, 0.2), 1.75em 1.75em 0 0em rgba(0, 123, 255, 0.2), 0em 2.5em 0 0em rgba(0, 123, 255, 0.2), -1.8em 1.8em 0 0em rgba(0, 123, 255, 0.2), -2.6em 0em 0 0em rgba(0, 123, 255, 0.5), -1.8em -1.8em 0 0em rgba(0, 123, 255, 0.7);
        }

        12.5% {
            box-shadow: 0em -2.6em 0em 0em rgba(0, 123, 255, 0.7), 1.8em -1.8em 0 0em #007bff, 2.5em 0em 0 0em rgba(0, 123, 255, 0.2), 1.75em 1.75em 0 0em rgba(0, 123, 255, 0.2), 0em 2.5em 0 0em rgba(0, 123, 255, 0.2), -1.8em 1.8em 0 0em rgba(0, 123, 255, 0.2), -2.6em 0em 0 0em rgba(0, 123, 255, 0.2), -1.8em -1.8em 0 0em rgba(0, 123, 255, 0.5);
        }

        25% {
            box-shadow: 0em -2.6em 0em 0em rgba(0, 123, 255, 0.5), 1.8em -1.8em 0 0em rgba(0, 123, 255, 0.7), 2.5em 0em 0 0em #007bff, 1.75em 1.75em 0 0em rgba(0, 123, 255, 0.2), 0em 2.5em 0 0em rgba(0, 123, 255, 0.2), -1.8em 1.8em 0 0em rgba(0, 123, 255, 0.2), -2.6em 0em 0 0em rgba(0, 123, 255, 0.2), -1.8em -1.8em 0 0em rgba(0, 123, 255, 0.2);
        }

        37.5% {
            box-shadow: 0em -2.6em 0em 0em rgba(0, 123, 255, 0.2), 1.8em -1.8em 0 0em rgba(0, 123, 255, 0.5), 2.5em 0em 0 0em rgba(0, 123, 255, 0.7), 1.75em 1.75em 0 0em #007bff, 0em 2.5em 0 0em rgba(0, 123, 255, 0.2), -1.8em 1.8em 0 0em rgba(0, 123, 255, 0.2), -2.6em 0em 0 0em rgba(0, 123, 255, 0.2), -1.8em -1.8em 0 0em rgba(0, 123, 255, 0.2);
        }

        50% {
            box-shadow: 0em -2.6em 0em 0em rgba(0, 123, 255, 0.2), 1.8em -1.8em 0 0em rgba(0, 123, 255, 0.2), 2.5em 0em 0 0em rgba(0, 123, 255, 0.5), 1.75em 1.75em 0 0em rgba(0, 123, 255, 0.7), 0em 2.5em 0 0em #007bff, -1.8em 1.8em 0 0em rgba(0, 123, 255, 0.2), -2.6em 0em 0 0em rgba(0, 123, 255, 0.2), -1.8em -1.8em 0 0em rgba(0, 123, 255, 0.2);
        }

        62.5% {
            box-shadow: 0em -2.6em 0em 0em rgba(0, 123, 255, 0.2), 1.8em -1.8em 0 0em rgba(0, 123, 255, 0.2), 2.5em 0em 0 0em rgba(0, 123, 255, 0.2), 1.75em 1.75em 0 0em rgba(0, 123, 255, 0.5), 0em 2.5em 0 0em rgba(0, 123, 255, 0.7), -1.8em 1.8em 0 0em #007bff, -2.6em 0em 0 0em rgba(0, 123, 255, 0.2), -1.8em -1.8em 0 0em rgba(0, 123, 255, 0.2);
        }

        75% {
            box-shadow: 0em -2.6em 0em 0em rgba(0, 123, 255, 0.2), 1.8em -1.8em 0 0em rgba(0, 123, 255, 0.2), 2.5em 0em 0 0em rgba(0, 123, 255, 0.2), 1.75em 1.75em 0 0em rgba(0, 123, 255, 0.2), 0em 2.5em 0 0em rgba(0, 123, 255, 0.5), -1.8em 1.8em 0 0em rgba(0, 123, 255, 0.7), -2.6em 0em 0 0em #007bff, -1.8em -1.8em 0 0em rgba(0, 123, 255, 0.2);
        }

        87.5% {
            box-shadow: 0em -2.6em 0em 0em rgba(0, 123, 255, 0.2), 1.8em -1.8em 0 0em rgba(0, 123, 255, 0.2), 2.5em 0em 0 0em rgba(0, 123, 255, 0.2), 1.75em 1.75em 0 0em rgba(0, 123, 255, 0.2), 0em 2.5em 0 0em rgba(0, 123, 255, 0.2), -1.8em 1.8em 0 0em rgba(0, 123, 255, 0.5), -2.6em 0em 0 0em rgba(0, 123, 255, 0.7), -1.8em -1.8em 0 0em #007bff;
        }
    }

    @keyframes load5 {

        0%,
        100% {
            box-shadow: 0em -2.6em 0em 0em #007bff, 1.8em -1.8em 0 0em rgba(0, 123, 255, 0.2), 2.5em 0em 0 0em rgba(0, 123, 255, 0.2), 1.75em 1.75em 0 0em rgba(0, 123, 255, 0.2), 0em 2.5em 0 0em rgba(0, 123, 255, 0.2), -1.8em 1.8em 0 0em rgba(0, 123, 255, 0.2), -2.6em 0em 0 0em rgba(0, 123, 255, 0.5), -1.8em -1.8em 0 0em rgba(0, 123, 255, 0.7);
        }

        12.5% {
            box-shadow: 0em -2.6em 0em 0em rgba(0, 123, 255, 0.7), 1.8em -1.8em 0 0em #007bff, 2.5em 0em 0 0em rgba(0, 123, 255, 0.2), 1.75em 1.75em 0 0em rgba(0, 123, 255, 0.2), 0em 2.5em 0 0em rgba(0, 123, 255, 0.2), -1.8em 1.8em 0 0em rgba(0, 123, 255, 0.2), -2.6em 0em 0 0em rgba(0, 123, 255, 0.2), -1.8em -1.8em 0 0em rgba(0, 123, 255, 0.5);
        }

        25% {
            box-shadow: 0em -2.6em 0em 0em rgba(0, 123, 255, 0.5), 1.8em -1.8em 0 0em rgba(0, 123, 255, 0.7), 2.5em 0em 0 0em #007bff, 1.75em 1.75em 0 0em rgba(0, 123, 255, 0.2), 0em 2.5em 0 0em rgba(0, 123, 255, 0.2), -1.8em 1.8em 0 0em rgba(0, 123, 255, 0.2), -2.6em 0em 0 0em rgba(0, 123, 255, 0.2), -1.8em -1.8em 0 0em rgba(0, 123, 255, 0.2);
        }

        37.5% {
            box-shadow: 0em -2.6em 0em 0em rgba(0, 123, 255, 0.2), 1.8em -1.8em 0 0em rgba(0, 123, 255, 0.5), 2.5em 0em 0 0em rgba(0, 123, 255, 0.7), 1.75em 1.75em 0 0em #007bff, 0em 2.5em 0 0em rgba(0, 123, 255, 0.2), -1.8em 1.8em 0 0em rgba(0, 123, 255, 0.2), -2.6em 0em 0 0em rgba(0, 123, 255, 0.2), -1.8em -1.8em 0 0em rgba(0, 123, 255, 0.2);
        }

        50% {
            box-shadow: 0em -2.6em 0em 0em rgba(0, 123, 255, 0.2), 1.8em -1.8em 0 0em rgba(0, 123, 255, 0.2), 2.5em 0em 0 0em rgba(0, 123, 255, 0.5), 1.75em 1.75em 0 0em rgba(0, 123, 255, 0.7), 0em 2.5em 0 0em #007bff, -1.8em 1.8em 0 0em rgba(0, 123, 255, 0.2), -2.6em 0em 0 0em rgba(0, 123, 255, 0.2), -1.8em -1.8em 0 0em rgba(0, 123, 255, 0.2);
        }

        62.5% {
            box-shadow: 0em -2.6em 0em 0em rgba(0, 123, 255, 0.2), 1.8em -1.8em 0 0em rgba(0, 123, 255, 0.2), 2.5em 0em 0 0em rgba(0, 123, 255, 0.2), 1.75em 1.75em 0 0em rgba(0, 123, 255, 0.5), 0em 2.5em 0 0em rgba(0, 123, 255, 0.7), -1.8em 1.8em 0 0em #007bff, -2.6em 0em 0 0em rgba(0, 123, 255, 0.2), -1.8em -1.8em 0 0em rgba(0, 123, 255, 0.2);
        }

        75% {
            box-shadow: 0em -2.6em 0em 0em rgba(0, 123, 255, 0.2), 1.8em -1.8em 0 0em rgba(0, 123, 255, 0.2), 2.5em 0em 0 0em rgba(0, 123, 255, 0.2), 1.75em 1.75em 0 0em rgba(0, 123, 255, 0.2), 0em 2.5em 0 0em rgba(0, 123, 255, 0.5), -1.8em 1.8em 0 0em rgba(0, 123, 255, 0.7), -2.6em 0em 0 0em #007bff, -1.8em -1.8em 0 0em rgba(0, 123, 255, 0.2);
        }

        87.5% {
            box-shadow: 0em -2.6em 0em 0em rgba(0, 123, 255, 0.2), 1.8em -1.8em 0 0em rgba(0, 123, 255, 0.2), 2.5em 0em 0 0em rgba(0, 123, 255, 0.2), 1.75em 1.75em 0 0em rgba(0, 123, 255, 0.2), 0em 2.5em 0 0em rgba(0, 123, 255, 0.2), -1.8em 1.8em 0 0em rgba(0, 123, 255, 0.5), -2.6em 0em 0 0em rgba(0, 123, 255, 0.7), -1.8em -1.8em 0 0em #007bff;
        }
    }
</style>