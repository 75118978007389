<script lang="ts">

import { isMobile } from '@/services/Mobile';
import Calculadora from '@/Components/Calculadora.vue';


export default {
    name: "PaginaCalculadora",
    props: {
    },
    components: {
        Calculadora,
    },
    data() {     
        
        
        return {
            mobile: isMobile(),

            tipo: '',
            ready: false,
            maxSteps: 0,

        };
    },
    methods: {
        scrollToTop(){
            window.scrollTo(0,0);
        },
        setCalculator(tipo: string) {
            this.tipo = tipo;
            this.ready = true;
            this.maxSteps = this.tipo == 'incorporadora' ? 5 : 10;
        },
        goToProjeto() {
            this.tipo = 'projeto';
            this.maxSteps = 10;
        },
        backToStart() {
            this.ready = false;
        }
    },
    async mounted(){
        this.scrollToTop();
    }
}

</script>

<template>
    <template v-if="!ready">
        <section class="w-full flex flex-col items-center justify-center h-[calc(100vh-5rem)]">
            <div class="ctn home-container" :class="mobile ? 'py-[7rem]' : ''">
                    <div class="flex flex-col gap-[1rem] text-center">
                        <h3 class="light-blue font-medium">Calculadora</h3>
                        <h2 class="font-bold text-[3rem]">Avalie indicadores da sua empresa <br> ou empreendimento</h2>
                        <p class="grey-text font-medium">Selecione o que deseja calcular abaixo</p>
                    </div>
                    
                    <div class="flex flex-col gap-[4rem] mt-[4rem] items-center">
                        <div class="relative h-[20rem] dark-blue-bg w-full max-w-[50rem] p-[2rem_2rem] rounded-2xl flex flex-col gap-[2.5rem] transition ease-in cursor-pointer text-white" data-aos="fade-right" data-aos-duration="1000">
                            <h1 class="font-bold text-[3rem]">Incorporadora</h1>
                            <p class="font-medium">Liquidez, Endividamento e Rentabilidade</p>
    
                            <div class="absolute bottom-0 right-0 p-[2rem_2rem] flex justify-end">
                                <a @click="setCalculator('incorporadora')"  class="text-center white-bg dark-blue min-w-[10rem] rounded-full p-[.5rem_1.5rem]">Calcular</a>
                            </div>
                        </div>
                        
                        <div class="relative h-[20rem] white-bg w-full max-w-[50rem] p-[2rem_2rem] rounded-2xl flex flex-col gap-[2.5rem] transition ease-in cursor-pointer"  data-aos="fade-left" data-aos-duration="1000">
                            <h1 class="font-bold text-[3rem]">Empreendimento</h1>
                            <p class="font-medium">ROI, Margem e Fluxo de Caixa</p>
    
                            <div class="absolute bottom-0 right-0 p-[2rem_2rem] flex justify-end">
                                <a @click="setCalculator('projeto')" class="text-center dark-blue-bg white-text min-w-[10rem] rounded-full p-[.5rem_1.5rem]">Calcular</a>
                            </div>
                        </div>
                    </div>
            </div>
        </section>
    </template>
    <template v-else>

        <Calculadora
            :tipo="tipo"
            :maxSteps="maxSteps"
            @backToStart="backToStart"
            @goToProjeto="goToProjeto"
            />

    </template>
</template>



