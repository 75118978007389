<script lang="ts">
import { PropType } from 'vue';
import Slide from './ImageSlide.vue';
import { SplideSlide } from '@splidejs/vue-splide';
import { Image } from '@/Types/Image'

interface DadosMedia {
    titulo: string;
    tipo?: string;
    imagens: Image[] | undefined;
    video: string;
    isShowing?: boolean;
}

export default {
    name: 'MediaMenu',
    props: {
        data: { type: Array<DadosMedia>, required: true },
    },
    components: {
        Slide,
        SplideSlide,
    },
    methods: {
        resetContentStatus() {
            this.data?.forEach((media) => media.isShowing = false)
        },
       
    },
    async mounted() {
        this.resetContentStatus()
    },
}
</script>

<template>
         <div class="rounded-xl">
            <div class="header grid grid-cols-3 rounded-lg gap-[1rem] md:gap-[.5rem] md:flex">
                <button v-for="media in data" @click="() => {resetContentStatus();media.isShowing = true;}" class="rounded-xl rounded-b-[0rem] bg-[rgb(51,51,51,0.05)] md:w-[18rem] md:h-[3.5rem]" :class="media.isShowing ? 'active-btn-pagina-projeto' : ''">{{ media.titulo }}</button>
            </div>
            <div class="content dark-blue-bg rounded-xl rounded-tl-[0rem]">
                <div v-for="media in data" class="dark-blue-bg rounded-3xl " :class="media.isShowing ? '' : 'hidden'">
                    <div v-if="media.tipo == 'obra'">
                        <Slide :images="media.imagens" dateField="data_atualizacao" margin-bottom="1rem" :borderRadiusImage="false" classes="rounded-xl rounded-tl-[0rem]"/>
                    </div>
                    <div v-else-if="media.tipo == 'video'">
                        <div v-html="media.video" class="aspect-video "></div>
                    </div>
                    <div v-else>
                        <Slide :images="media.imagens" :borderRadiusImage="false" classes="rounded-xl rounded-tl-[0rem]"/>
                    </div>
                </div>
                
            </div>
        </div>
        
       
</template>

<style scoped>

.active-btn-pagina-projeto {
  background-color: rgb(0, 26, 66);
  color: #fff;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
</style>