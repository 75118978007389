<script lang="ts">

import { colors } from '@/styles/styles';


export default {
    name: 'Counter',
    props: {
        target: {type: Number, default: 0},
        tipo: {type: String, default: ''},
    },
    data() {
        return {
            text: 1,
            value: 1,

            colors,

            textHighlight: false,

        }
    },
    methods: {
        updateCount() {
            const startTime = Date.now();
            const duration = 2000;
            
            const newValue = this.target.toString();
            const size = newValue.length;
            const newTarget = size > 6 ? Number(newValue[0]+newValue[1]+newValue[2]) : this.target; 
            
            let speed = duration / newTarget;

            let counter = setInterval(() => {
                let timeElapsed = Date.now() - startTime;
                this.value = Math.floor(timeElapsed / speed);
                this.text = this.value;

                if(this.value >= newTarget) {
                    clearInterval(counter);
                    this.text = newTarget;
                    setTimeout(() => {this.textHighlight = true;},  1000);
                }
            }, speed);
        },       
    },
    mounted() {
        this.updateCount();
    },

}
</script>

<template>
    <span
        class="count font-bold text-[3.5rem] tabular-nums"
        :class="{'text-highlight-animation': textHighlight}"
        :data-target="target"
        >
        {{ tipo == 'money' ? `R$ ${text} MM` : text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}
    </span>
</template>