<script lang="ts">
import {inject, PropType} from 'vue';
import InvestidorGateway from '@/infra/gateway/investidorGateway';
import SliderSelector from './SliderSelector.vue';
import SubmitButton from './SubmitButton.vue';
import { ToDate, Money, Percent } from '@/utils/formats';
import {copyToClipboard, goToUrl, getOffset} from '@/services/Browser';
import { Processing } from '@/Types/General';
import copyIcon from '@/assets/copy.svg';
import medalIcon from '@/assets/medal.svg';

import {InvestimentoValoresForm, InvestimentoTermosForm, InvestimentoAutenticacaoForm , InvestimentoAssinaturaForm, Termos} from '@/Types/Investimento';
import { ModelForm } from "../services/Form";
import Loading from '@/Components/Loading.vue';

//FORMULARIOS
import FormularioInvestimentoValores from '@/Components/Formularios/FormularioInvestimentoValores.vue';
import FormularioInvestimentoTermos from '@/Components/Formularios/FormularioInvestimentoTermos.vue';
import FormularioInvestimentoAutenticacao from '@/Components/Formularios/FormularioInvestimentoAutenticacao.vue';
import FormularioInvestimentoAssinatura from '@/Components/Formularios/FormularioInvestimentoAssinatura.vue';
import { isMobile } from '@/services/Mobile';



export default {
    name: "CardInvestimento",
    components : {
        SliderSelector,
        SubmitButton,
        FormularioInvestimentoValores,
        FormularioInvestimentoTermos,
        FormularioInvestimentoAutenticacao,
        FormularioInvestimentoAssinatura,

        Loading,
    },
    props : {
        captacao: {type: Object, required: true},
        saldoCarteira: {type: Number, required: true},
    },
    methods: {
        async goToIndex(index: number){
            switch(index){
                case 2:
                    const responseTermos = await this.investidorGateway.getInformacoesTermos();
                    this.termos = responseTermos.termos;
                    this.investimentoTermos.loadInstance();
                    this.moveToCardStep(index);
                    break;
                case 3:
                    const responseAuth = await this.investidorGateway.getInformacoesAutenticacao();
                    this.auth = responseAuth;
                    this.investimentoAutenticacao.loadInstance();
                    this.investimentoAutenticacao.instance.sign_method = 'whatsapp';

                    this.moveToCardStep(index);
                    break;
                case 4:
                    this.moveToCardStep(index);

                    //SETUP FORMULARIO ASSINATURA
                    this.investimentoAssinatura.loadInstance();
                    this.investimentoAssinatura.instance.de_acordo = true;
                    this.processing.setValue(true);

                    //GET DADOS PARA GERAR A CCB
                    const assinaturaData = await this.investidorGateway.getInformacoesAssinatura();
                    this.assinaturaData = assinaturaData;

                    //GERANDO A CCB
                    const getCCB = await this.investidorGateway.postGerarCCBSocinal(this.assinaturaData?.sign_method);
                    const signerKey = getCCB.data.signer_req_key
                    this.reqKey = signerKey;

                    //CRIAR WIDGET CLICKSIGN
                    (this.$refs.formAssinatura as typeof FormularioInvestimentoAssinatura).mount_clicksign(this.reqKey)
                    break;
                case 5: 
                    const pagamentoData = await this.investidorGateway.getInformacoesPagamento();
                    const responsePix =  await this.investidorGateway.postGerarPix();

                    this.pagamentoData = pagamentoData;
                    this.pixCopiaECola = responsePix.data.pix_emv;
                    this.pixQRCode = responsePix.data.qrcode_b64_image;

                    this.moveToCardStep(index);
                    break;
                case 6:
                    const responseFinalizar = await this.investidorGateway.getInformacoesFinalizar();
                    this.finalizarData = responseFinalizar;
                    this.moveToCardStep(index);

                    break;
            }
        },
        moveToCardStep(index: number){
            this.goToTop();
            this.etapa = index;
            (this.$refs.slider as typeof SliderSelector).moveToSelectedIndex(index - 1);
        },
        canSubmit() {
            return !this.processing.isBusyOrSetBusy();  
        },

        async goToEtapaInvestimento(){
            this.processing.setValue(true);
            const response = await this.investidorGateway.postPagamentoInvestimento();
            this.processing.setValue(false);
            if(response?.success){
                this.etapa = 7; 
                this.goToIndex(this.etapas[response.data.proximo_passo]);
            }
        },

        resetSubmit(success: boolean) {
            this.processing.setValue(false);
            if (!success) return;
        },
        async handleSubmit(evento: SubmitEvent, form: ModelForm) {
            if(!this.canSubmit()) return;
            const response = await form.save(evento, this.resetSubmit, false, false)
            if(response){
                this.etapa = 7  
                this.goToIndex(this.etapas[response.proximo_passo])
            }
        },

        goToTop() {
            const element = this.$refs.cardinvestimento;
            const top = this.getOffset((element as HTMLElement)).top;
            window.scroll({
                top: top - 80,
                behavior: 'smooth'
            });
        },
    },
    data() {

        const investimentoValores = new InvestimentoValoresForm(
            //@ts-ignore
            (this.investidorGateway as InvestidorGateway).postValoresInvestimento,
            (any:any)=>any,
        )

        const investimentoTermos = new InvestimentoTermosForm(
            //@ts-ignore
            (this.investidorGateway as InvestidorGateway).postTermosInvestimento,
            (any:any)=>any,
        )

        const investimentoAutenticacao = new InvestimentoAutenticacaoForm(
            //@ts-ignore
            (this.investidorGateway as InvestidorGateway).postAutenticacaoInvestimento,
            (any:any)=>any,
        )

        const investimentoAssinatura = new InvestimentoAssinaturaForm(
            //@ts-ignore
            (this.investidorGateway as InvestidorGateway).postAssinaturaInvestimento,
            (any:any)=>any,
        )

        return {
            processing : new Processing(),
            copyIcon,
            medalIcon,

            //utils
            ToDate,
            Money,
            Percent,
            copyToClipboard,
            goToUrl,
            getOffset,

            mobile: isMobile(),
            
            //STEPS DATA
            termos: Object as PropType<Termos> | undefined,
            auth: undefined as Object | undefined,
            pagamentoData: undefined as {quantidade_cotas: number, quantidade_pagar: number, horas_expiracao: number} | undefined,
            assinaturaData: undefined as Object | undefined,
            reqKey: undefined as String | undefined,
            finalizarData: undefined as Object | undefined ,
            pixCopiaECola: '',     
            pixQRCode: '',
            
            //ETAPAS HANDLING
            etapa : 1,
            etapas : {
                'termos' : 2,
                'autenticacao' : 3,
                'assinatura' : 4,
                'pagamento' : 5,
                'investimento' : 6,
                'loading' : 7
            },



            //FORMS MODELS
            investimentoValores,
            investimentoTermos,
            investimentoAutenticacao,
            investimentoAssinatura

        }
    },
    setup() {
        return {
            investidorGateway: inject<InvestidorGateway>('investidorGateway')!,
        }
    },
    async mounted(){        
        this.investimentoValores.loadInstance()
        this.investimentoValores.instance.quantidade_cotas = 1;
        this.investimentoValores.instance.saldo = (this.saldoCarteira > 0);
    }
}

</script>

<template>
    <div ref="cardinvestimento" class="bg-white rounded-[1.5rem] py-[3rem] md:py-[4.5rem] px-[1.5rem] sm:px-[5rem] overflow-x-hidden">
        <div class="font-medium font-title">Investimento</div>
        <SliderSelector 
            ref="slider"
            class="mt-[4.5rem]" 
            :free-select="false"
            :sections="['Valores','Termos','Autenticação','Assinatura','Pagamento','Investimento']"  
            @change="() => {}"
            :justify-between="true"/>

        <div v-if="etapa == 1 && investimentoValores.instance" id="investment">
            <form @submit.prevent="(event)=>handleSubmit(event, investimentoValores)" action="POST" enctype='multipart/form-data'>
                <FormularioInvestimentoValores
                    :valores-investimento="investimentoValores.instance"
                    :captacao="captacao"
                    :saldo="saldoCarteira"/>
                    <SubmitButton 
                        :no-padding="true"
                        :no-block="true"
                        :noBorder="true"
                        text="Avançar"
                        classes="text-white font-medium dark-blue-bg rounded-[.5rem] ml-auto mr-0 md:absolute md:right-0 md:top-[92.3%] min-[1162px]:top-[87%]"
                        :disabled="processing.getValue()"
                    />
            </form>

        </div>

        <div  v-else-if="etapa == 2 && termos" id="investment">
            <form @submit.prevent="(event)=>handleSubmit(event, investimentoTermos)" action="POST" enctype='multipart/form-data'>
                <FormularioInvestimentoTermos
                    :termos="termos"
                    :termos-instance="investimentoTermos.instance"
                />
                <div class="border-standard">
                </div>
                <div class="flex mt-[1.2rem] gap-4 items-center justify-end">
                    <SubmitButton 
                        :no-padding="true"
                        :no-block="true"
                        :noBorder="true"
                        text="Avançar"
                        classes="text-white font-medium dark-blue-bg rounded-[.5rem]"
                        :disabled="processing.getValue()" />
                </div>
            </form>
        </div>
 
        <div v-else-if="etapa == 3 && auth" id="investment">
            <form @submit.prevent="(event)=>handleSubmit(event, investimentoAutenticacao)" action="POST" enctype='multipart/form-data'>
                <FormularioInvestimentoAutenticacao
                    :autenticacao-instance="investimentoAutenticacao.instance"
                    :auth-data="auth"
                />
                <div class="border-standard">
                </div>
                <div class="flex mt-[1.2rem] gap-4 items-center justify-end">
                    <SubmitButton 
                        :no-padding="true"
                        :no-block="true"
                        :noBorder="true"
                        text="Avançar"
                        classes="text-white font-medium dark-blue-bg rounded-[.5rem]"
                        :disabled="processing.getValue()" />
                </div>
            </form>

        </div>
        <div v-else-if="etapa == 4" id="investment">
            <form @submit.prevent="(event)=>handleSubmit(event, investimentoAssinatura)" action="POST" enctype='multipart/form-data'>
                <FormularioInvestimentoAssinatura
                    ref="formAssinatura"
                    :req-key="reqKey"
                    :processing="processing"
                    :assinatura-data="assinaturaData"
                />
                <div class="border-standard">
                </div>
                <div class="flex mt-[1.2rem] gap-4 items-center justify-end">
                    <SubmitButton 
                        :no-padding="true"
                        :no-block="true"
                        :noBorder="true"
                        text="Avançar"
                        classes="text-white font-medium dark-blue-bg rounded-[.5rem]"
                        :disabled="processing.getValue()" />
                </div>
            </form>
        </div>
        <div v-else-if="etapa == 5" id="investment">
            <div class="flex justify-between gap-[2rem] py-8 flex-col min-[1037px]:flex-row">
                <div class="flex flex-col font-large gap-5 min-[1037px]:max-w-[20%]">
                    <p class="grey-text font-medium border-bottom-grey">Resumo</p>
                    <div class="flex justify-between gap-8 font-medium md:flex-col">
                        <div>
                            <p class="grey-text">Cotas</p>
                            <p>{{ pagamentoData?.quantidade_cotas }}</p>
                        </div>
                        <div>
                            <p class="grey-text">Valor a ser Pago</p>
                            <p>{{ Money(pagamentoData?.quantidade_pagar) }}</p>
                        </div>
                    </div>
                </div>
                <div class="flex flex-col gap-5 min-[1037px]:max-w-[35%]">
                    <p class="grey-text font-large font-medium border-bottom-grey">Pagamento</p>
                    <div class="flex font-medium justify-between">
                        <div class="flex flex-col gap-4 min-[1037px]:gap-6">
                            <p class="grey-text">A única maneira de realizar o pagamento do seu investimento é através do QRCode PIX que você vê na tela.</p>
                            <p class="grey-text">O QRCode tem duração de 1 hora. Caso este tempo expire, gere um novo acessando o Painel do Investidor!</p>
                        </div>
                    </div>
                </div>
                <div class="flex flex-col min-[1037px]:max-w-[40%] min-[1037px]:gap-[1rem]">
                    <h2 class="font-bold text-[2rem]">
                        Aproxime a câmera para ler o QR Code
                     </h2>
                    <div class="flex font-large min-[1037px]:gap-0">
                        <div class="grey-text pointer flex items-center w-[20rem] min-[1037px]:justify-center" 
                            @click="copyToClipboard(pixCopiaECola)">
                            ou copie e cole
                            <img :src="copyIcon" class="filter-grey h-[35%] min-[1037px]:h-[22%]">
                        </div>
                        <div class="h-[15rem] flex items-center min-w-[15rem] ">
                            <img v-if="pixQRCode" :src="pixQRCode" class="h-[15rem] w-[15rem]">
                            <Loading v-else class="save-loading" :size="2.5" />
                        </div>
                    </div>
                    
                </div>  

            </div>

                <div class="border-standard">
                </div>
                <div class="flex mt-[1.2rem] gap-4 items-center justify-end">
                    <SubmitButton 
                        @click="goToEtapaInvestimento()"
                        :no-padding="true"
                        :no-block="true"
                        :noBorder="true"
                        text="Avançar"
                        classes="text-white font-medium dark-blue-bg rounded-[.5rem]"
                        :disabled="processing.getValue()" />
                </div>
        </div>
        <div v-else-if="etapa == 6 && finalizarData" id="investment">
            <div class="pt-8">
                <img :src="medalIcon" class="filter-dark-blue w-[5rem] h-[5rem]">
                <div>
                    <h1 class="font-xxl font-medium">Parabéns, {{ finalizarData?.nome_investidor }}!</h1>
                <h1 class="font-xxl font-medium grey-text">
                    <span v-if="finalizarData?.pagamento_realizado">
                        O seu investimento foi realizado!
                    </span>
                    <span v-else>
                        Você tem até {{ finalizarData?.horas_expiracao }} horas para realizar o pagamento!
                    </span>
                </h1>
                </div>

                <div class="border-standard mt-[2rem]"></div>

                <div class="flex flex-wrap gap-[1rem] justify-between mt-[.5rem]">
                    <p class="">Você encontrará todos seus investimentos no Painel do Investidor!</p>
                    <div class="mx-auto min-[850px]:mx-0 min-[850px]:self-end">
                        <SubmitButton 
                            @click="goToUrl('/dashboard/investidor/')"
                            :no-padding="true"
                            :no-block="true"
                            :noBorder="true"
                            text="Ir ao Painel do Investidor"
                            classes="text-white font-medium dark-blue-bg rounded-[.5rem] max-h-[3.6rem] min-[850px]:mt-[.5rem]"
                            :disabled="processing.getValue()" />
                    </div>
                </div>

            </div>
        </div>

        <div v-else class="py-[13rem]">
            <Loading class="save-loading" :size="2.5" />
        </div>
    
    </div>
</template>



<style scoped>

@keyframes investiment-transition-right-left {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0%);
    }
}

#investment {
            transform: translateX(100%);
            animation: investiment-transition-right-left linear .3s;
            animation-duration: .5s;
            animation-fill-mode: forwards;
        }
</style>