<script lang="ts">
import { PropType, defineComponent, inject } from 'vue';
import { InputType, Option } from '@/Types/InputTypes';
import { MaskType } from '@/Types/MaskTypes';
import { isMobile } from '@/services/Mobile';
import  InputField from '@/Components/InputField.vue';
import { colors } from '@/styles/styles';
import { IInvestimentoValores } from '@/Types/Investimento';
import SubmitButton from '@/Components/SubmitButton.vue';
import { ToDate, Money, Percent } from '@/utils/formats';
import { jurosAnuaisParaMensais } from '@/utils/MathHelper';

export default defineComponent({
    name: "FormularioInvestimentoValores",
    props : {
        saldo : {type: Number, default: null},
        captacao: {type: Object, required: true},
        valoresInvestimento:{type: Object as PropType<IInvestimentoValores>, required: true},
    },
    components: {
        InputField,
        SubmitButton
    },
    data() {
        return {
            InputType,
            mobile: isMobile(),
            colors,

            Money,
            Percent,
            ToDate,
            jurosAnuaisParaMensais,
            MaskType,

            valorSerPago: (this.valoresInvestimento.quantidade_cotas * this.captacao.valor_cota),

            saldoUtilizado: 0,
        }
    },
    methods: {

        checkSaldo(){
            const valorInvestimento = (this.valoresInvestimento.quantidade_cotas * this.captacao.valor_cota);
            if(this.valoresInvestimento.saldo){
                this.valorSerPago = valorInvestimento >= this.saldo ? valorInvestimento - this.saldo : 0;
                this.saldoUtilizado = this.valorSerPago > 0 ? this.saldo :  valorInvestimento;
                return;
            }
            this.valorSerPago = valorInvestimento;
            this.saldoUtilizado = 0;
        },
    },
    mounted() {
        this.checkSaldo();
    }
})

</script>

<template>
    <div class="flex flex-wrap md:flex-row">
        <div class="flex flex-col justify-between gap-[2rem] w-full py-[1.5rem] md:flex-row md:gap-0 min-[1162px]:w-[50%]">
            <div class="flex flex-col gap-[2rem] mt-[1.5rem] md:mt-0 md:min-w-[50%] min-[1162px]:max-w-[50%]">
                <h2 class="grey-label text-[1.8rem] font-medium">Quantas cotas você deseja?</h2>
                <InputField
                    :styles="{
                        'background-color' : colors.lightBlue
                    }"
                    :mask="MaskType.NUMERO"
                    v-model:value="valoresInvestimento.quantidade_cotas"
                    inputContainerClass="flex gap-[1.5rem] items-center"
                    :ceilValue="captacao?.cotas_disponiveis"
                    :noLabel="true"
                    :callback="checkSaldo"
                    :type="InputType.UPDATE_NUMBER"
                    name=""/>
            </div>
            <div class="flex flex-col w-full md:min-w-[50%] min-[1162px]:max-w-[50%]">
                <div class="md:mx-auto gap-[2.5rem] flex flex-col">
                    <div class="grey-label text-[1.8rem] font-medium">Total do Aporte</div>
                    <div class="text-[2.8rem] font-bold">{{Money(valoresInvestimento.quantidade_cotas * captacao.valor_cota)}}</div>
                </div>
            </div>
        </div>

       

        <div class="w-full py-[1.5rem] justify-center md:py-0 min-[1162px]:py-[1.5rem] min-[1162px]:w-[0rem]">
            <div class="">
                <div class="w-full border-secondary" :class="saldo > 0 ? 'min-[1162px]:h-[13rem]' : 'min-[1162px]:h-[9rem]'">
                </div>
            </div>
        </div>
        
        <div class="flex flex-col gap-[3rem] w-full py-[1.5rem] md:flex-row md:gap-0 md:pb-[2rem] min-[1162px]:w-[50%] min-[1162px]:py-0">
            <div class="flex flex-col w-full md:min-w-[50%] min-[1162px]:py-[1.5rem] min-[1162px]:pl-[5rem] min-[1162px]:max-w-[50%]">
                <div class="flex flex-col gap-[2.5rem]">
                    <h2 class="grey-label text-[1.8rem] font-medium">
                        {{ valoresInvestimento.saldo ? 'Restante em Carteira' : 'Saldo em Carteira' }}
                    </h2>
                    <p class="text-[2.8rem]">{{ Money(saldo - saldoUtilizado) }}</p>
                </div>
                <div v-if="saldo > 0" class="flex pointer mt-[1rem] gap-[1rem]">
                    <InputField
                        @click="checkSaldo()"
                        name=""
                        :noLabel="true"
                        checkBoxColor="light-blue-bg"
                        :isSelected="valoresInvestimento.saldo"
                        v-model:value="valoresInvestimento.saldo"
                        :type="InputType.CHECKBOX"
                    />
                    <p class="flex items-center grey-label" @click="() => {valoresInvestimento.saldo = !valoresInvestimento.saldo; checkSaldo()}">
                        Usar saldo da carteira
                    </p>    
                </div>                

            </div>
            
            <div class="w-full justify-center md:hidden">
                <div class="">
                    <div class="w-full border-secondary">
                    </div>
                </div>
            </div>
    
            <div class="flex flex-col min-[1162px]:py-[1.5rem] w-full mb-[1.5rem] md:min-w-[50%] md:mb-0 min-[1162px]:max-w-[50%]">
                <div class="md:mx-auto gap-[2.5rem] flex flex-col">
                    <h2 class="grey-label text-[1.8rem] font-medium">Valor a ser pago</h2>
                    <p class="text-[2.8rem]">{{Money(valorSerPago)}}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="border-standard"></div>
    <div class="flex flex-col md:flex-row mt-[2rem] gap-4 items-center h-[8rem] md:h-[6rem] justify-between md:w-[87%]">
        <p class="font-small grey-label" v-if="captacao.tipo_carencia == 'P'">
            Ao investir {{ Money(valoresInvestimento.quantidade_cotas * captacao.valor_cota) }}, a partir de {{ ToDate(captacao.primeira_parcela_carencia) }} até {{ ToDate(captacao.ultima_parcela_carencia)  }},
                você receberá <b class="dark-blue">{{ captacao.parcelas_carencia }} parcelas 
                mensais de {{
                    Money(valoresInvestimento.quantidade_cotas * captacao.valor_cota * Number(captacao.rentabilidade_juros))
                }}</b>, equivalentes aos juros de {{captacao.rentabilidade_minima}}.
                Após esse período, até {{ ToDate(captacao.data_de_previsao_de_pagamento)  }}, você receberá <b class="dark-blue">{{ captacao.parcelas - captacao.parcelas_carencia }} parcelas mensais de 
                {{ captacao.tipo_rentabilidade_minima == 'POS'? 
                `${Money(captacao.valor_parcela * valoresInvestimento.quantidade_cotas )}, corrigidas pelo ${captacao.indice_tir_garantida}.` 
                : `${Money(captacao.valor_parcela * valoresInvestimento.quantidade_cotas )}` }}</b>, referente a juros acrescidos da amortização do principal investido.

                {{ captacao.vgv_percentual_investidores > 0 ? 
                `Na última parcela, será paga a diferença até alcançar ${Percent(captacao.percentual_vgv_por_cota * valoresInvestimento.quantidade_cotas, true, 6)} do Valor Geral de Vendas (VGV) realizado do empreendimento, com correção pelo INCC.` 
                : '' }}
        </p>
        <p class="font-small grey-label" v-else>
            Ao investir {{ Money(valoresInvestimento.quantidade_cotas * captacao.valor_cota) }}, a partir do dia {{ ToDate(captacao.data_primeiro_pgto) }} até o dia {{ ToDate(captacao.data_de_previsao_de_pagamento)  }},
                você receberá {{ captacao.parcelas }} parcelas 
                mensais de
                {{ captacao.tipo_rentabilidade_minima == 'POS'? 
                `${Money(captacao.valor_parcela * valoresInvestimento.quantidade_cotas )}, corrigidas pelo ${captacao.indice_tir_garantida}.` 
                : `${Money(captacao.valor_parcela * valoresInvestimento.quantidade_cotas )}.` }}

                {{ captacao.vgv_percentual_investidores > 0 ? 
                `Na última parcela, será paga a diferença até alcançar ${Percent(captacao.percentual_vgv_por_cota * valoresInvestimento.quantidade_cotas, true, 6)} do Valor Geral de Vendas (VGV) realizado do empreendimento, com correção pelo INCC.` 
                : '' }}
        </p>
        
    </div>
</template>
