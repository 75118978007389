<script lang="ts">
import { defineComponent, PropType } from 'vue';
import InputField from '@/Components/InputField.vue';
import { InputType } from '@/Types/InputTypes';
import { ICalculadoraStep5 } from '@/Types/Calculadora'
import { MaskType } from '@/Types/MaskTypes';
import { Loader } from '@googlemaps/js-api-loader';
import { GOOGLE_MAPS_API_KEY } from '@/env/variables';
import GeneralGateway from '@/infra/gateway/generalGateway';

export default defineComponent({
    name: "FormularioCalculadoraStep5",
    components: {
        InputField,
    },
    props: {
        calculadora: {type: Object as PropType<ICalculadoraStep5>, required: true},
        editable: {type: Boolean},
    },
    inject:["generalGateway"],
    data() {
        return {
            InputType,
            MaskType,

            addressChoices: [] as undefined | any,
            publicoAlvoChoices: [] as undefined | any,
        }
    },
    methods: {
        async loadAddresses(value: string) {
            const loader = new Loader({
                apiKey: GOOGLE_MAPS_API_KEY,
                version: "weekly",
            });

            const { AutocompleteService } = await loader.importLibrary("places")

            const service = new AutocompleteService()

            const res = service.getPlacePredictions({input: value, componentRestrictions: {country: 'br'}}, (predictions, status)=>{
                this.addressChoices = predictions?.map(prediction => ({
                    code: prediction.description,
                    name: prediction.description
                }))
            })


        },
        async getConstants() {
            const response = await (this.generalGateway as GeneralGateway).getConstants('publicos_alvo');
            this.publicoAlvoChoices = response;
        }
    },
    mounted() {
        this.getConstants();
    }
})
</script>

<template>
    <div class="step flex flex-col gap-[1rem]" id="step-3">
        <div>
            <p class="font-bold">Passo 5: Identificação</p>
        </div>

        <div class="grid grid-cols md:grid-cols-2">
            <InputField
                name="nome_projeto"
                :is-required="true"
                v-model:value="calculadora.nome_projeto"
                placeholder=" "
                labelStyles="font-size: 1.4rem"
                label="Nome do empreendimento"
                font-size="15px"
                class="w-full"
                :type="InputType.TEXT"
                :col-span="2"/>
            <InputField
                name="spe"
                v-model:value="calculadora.spe"
                placeholder=" "
                labelStyles="font-size: 1.4rem"
                label="Tem SPE?"
                font-size="15px"
                :type="InputType.CHECKBOX"
                :col-span="1"/>
            <InputField
                name="publico_alvo"
                :is-required="true"
                v-model:value="calculadora.publico_alvo"
                placeholder=" "
                labelStyles="font-size: 1.4rem"
                label="Público Alvo"
                :styles="{'font-size': '1.4rem!important'}"
                :type="InputType.SELECT"
                :options="publicoAlvoChoices"
                :col-span="1"/>
            <InputField
                name="endereco_completo"
                @get-options="loadAddresses"
                :auto-complete-options="addressChoices"
                :is-required="true"
                v-model:value="calculadora.endereco_completo"
                placeholder=" "
                labelStyles="font-size: 1.4rem"
                :styles="{'font-size': '14px!important'}"
                label="Endereço completo"
                font-size="15px"
                class="w-full"
                :type="InputType.AUTOCOMPLETE"
                :col-span="2"/>
        </div>
    </div>

</template>

<style scoped>

</style>