<script lang="ts">
import { defineComponent, inject, PropType } from 'vue';
import InputField from '@/Components/InputField.vue';
import { InputType } from '@/Types/InputTypes';
import { MaskType } from '@/Types/MaskTypes';
import { ICalculadoraStep1 } from '@/Types/Calculadora';


export default defineComponent({
    name: "FormularioCalculadoraStep1",
    components: {
        InputField,    },
    props: {
        calculadora: {type: Object as PropType<ICalculadoraStep1>, required: true},
        editable: {type: Boolean},
    },
    data() {
        return {
            InputType,
            MaskType,
        }
    },
})
</script>

<template>
    <div class="step flex flex-col gap-[1rem]" id="step-1">
        <p class="font-bold">Passo 1: Identificação</p>
        <div class="grid grid-cols md:grid-cols-2">
            <InputField
                name="nome" 
                :is-required="true"
                v-model:value="calculadora.nome"
                placeholder=" "
                labelStyles="font-size: 1.4rem"
                label="Seu Nome"
                font-size="15px"
                class="w-full"
                :type="InputType.TEXT"
                :col-span="1"/>
            <InputField
             name="email" 
                :is-required="true"
                v-model:value="calculadora.email"
                placeholder=" "
                labelStyles="font-size: 1.4rem"
                label="Seu E-mail"
                font-size="15px"
                class="w-full"
                :type="InputType.TEXT"
                :col-span="1"/>
            <InputField
                name="telefone" 
                :is-required="true"
                v-model:value="calculadora.telefone"
                placeholder=" "
                labelStyles="font-size: 1.4rem"
                label="Seu Telefone"
                font-size="15px"
                class="w-full"
                :type="InputType.TEXT"
                :mask="MaskType.TELEFONE"
                :col-span="1"
                maxLength="15"/>
        </div>
    </div>

</template>
