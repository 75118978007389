<script lang="ts">
import { defineComponent, inject, PropType } from 'vue';
import InputField from '@/Components/InputField.vue';
import { InputType, FileType } from '@/Types/InputTypes';
import { MaskType } from '@/Types/MaskTypes';
import { ICalculadoraStep4 } from '@/Types/Calculadora';


export default defineComponent({
    name: "FormularioCalculadoraStep4",
    components: {
        InputField,
    },
    props: {
        calculadora: {type: Object as PropType<ICalculadoraStep4>, required: true},
        editable: {type: Boolean},

    },
    data() {
        return {
            InputType,
            MaskType,
            FileType,
        }
    },
})
</script>

<template>
    <div class="step flex flex-col gap-[1rem]" id="step-3">
        <div>
            <p class="font-bold">Passo 4: DRE Anual Atualizado</p>
            <div id="arquivo_dre-error" class="dark-blue error-field inadimplente font-small p-4 ml-[1rem] mr-[1.3rem] py-[.5rem] hidden rounded-lg">
            </div>  
            <p class="text-[1.2rem]">Preencha os campos e
                <label for="arquivo_dre" class="hover:underline cursor-pointer font-medium">clique aqui</label>
                <InputField name="arquivo_dre" v-model:value="calculadora.arquivo_dre" :type="InputType.FILE" :file-type="FileType.PDF" hidden/>
                para importar um arquivo (opcional)</p>

            </div>

        <div class="grid grid-cols md:grid-cols-2">
            <InputField
                name="receita_bruta"
                :mask="MaskType.DINHEIRO"
                :is-required="true"
                v-model:value="calculadora.receita_bruta"
                placeholder=" "
                labelStyles="font-size: 1.4rem"
                label="Faturamento Bruto"
                font-size="15px"
                :type="InputType.TEXT"
                :col-span="1"/>
            <InputField
                name="lucro_liquido"
                :mask="MaskType.DINHEIRO"
                :is-required="true"
                v-model:value="calculadora.lucro_liquido"
                placeholder=" "
                labelStyles="font-size: 1.4rem"
                label="Lucro Líquido"
                font-size="15px"
                :type="InputType.TEXT"
                :col-span="1"/>
        </div>
    </div>

</template>
